import * as React from "react";
import {
  GridToolbar,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridCellEditStopReasons,
} from "@mui/x-data-grid";
import //   randomTraderName,
"@mui/x-data-grid-generator";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { dataGridStyle } from "../../../shared/style/mui-table-style/MuiTableStyle";
import { getTogglableColumns } from "../../../shared/lib/columnMenuPanel";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import {
  Button,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Pagination,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDeleteProduct, useNewGetProductList, useUpdateProduct } from "../../../service/product/productHooks";
import { statusOptions } from "../../../shared/const/statusOptions";
import { newProductRequestDto, updateProductDto } from "../../../service/product/types";
import BrandEditCell from "../../../widget/form/ui/SelectBrandCell";
import { seasonOptions } from "../../../shared/const/seasonOption";
import SelectForm from "../../../widget/form/ui/SelectForm";
import ShowInventoryButton from "../../../widget/show-inventory-modal/ui/ShowInventoryButton";
import { CreateProductButton } from "./create-product-modal/CreateProductButton";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import { HIDE_FILETER, SHOW_COLUMN, STATE_OPTION } from "../const/columns";
import { isKeyboardEvent } from "../lib/isKeyboardEvent";
import { EditTextarea } from "./EditTextarea";
import { UpdateProductButton } from "./update-product-modal/UpdateProductButton";
import { brandIconOptions, brandOptions } from "../../side-navigation/const/brandIconsOption";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useShowColumnToggleStore } from "../../../store/showColumnToggleStore";
import ExportToExcelButtonInProductTable from "./ExportToExcelButtonInProductTable";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import RowStack from "../../../shared/ui/RowStack";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import { useUserInfoState } from "../../../store/UserInfoStore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BulkStatusUpdateModal from "./bulk-status-update-modal/BulkStatusUpdateModal";
import CustomPagination from "./table-pagination/CustomPagination";
import { statusType } from "../type/types";
export const initialState: newProductRequestDto = {
  brand: "",
  status: "",
  page: 1,
  page_size: 100,
  sortby: "BarCode",
  direction: "asc",
  searchText: "",
};



const DELEABLE_ROLD_ID = [101, 100];

export default function NewProductTable() {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({}); // 현재 변경중인 값
  const [selected, setSelected] = React.useState<number[]>([]);
  const { showColumnToggle, setShowColumnToggle } = useShowColumnToggleStore((state) => state);
  const [params, setParams] = React.useState<newProductRequestDto>({ ...initialState });
  const RoleID = useUserInfoState((state) => state.RoleID);
  const { mutateAsync: deleteProduct } = useDeleteProduct();
  const handleChangeParams = (e: any) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.trim().toLocaleLowerCase() : value;
    setParams((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  //도메 메인 단종 변경

  //상품 리스트
  const { data: rows, refetch, isLoading, isFetching } = useNewGetProductList(params);
  const { mutate: updateProduct } = useUpdateProduct((data: any) => {
    refetch;
  });

  //편집 중지
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  // const {Barcode, ProductName, Brand, Color, Size, Season, Memo0,SKU_ID, Status, ORderLink, QuotedPrice, SalePrice,
  //   ChinaCost, LocalDeliveryFee, KoreaCost, DeliveryPrice, RocketMargin, OurMargin,TotalCost, AccountingCost, SupplierCompany, VAT } = newRow;
  const processRowUpdate = (newRow: GridRowModel) => {
    console.log("newRow : ",newRow);

    const updatedRow = { ...newRow, isNew: false };

    const data: updateProductDto = {
      BarCode: newRow.BarCode,
      ProductName: newRow.ProductName,
      Brand: newRow.Brand,
      Color: newRow.Color,
      Size: newRow.Size,
      Season: newRow.Season,
      Memo0: newRow.Memo0,
      Memo00: newRow.Memo00,
      SKU_ID: newRow.SKU_ID,
      Status: newRow.Status as "메인" | "도매" | "단종",
      OrderLink: newRow.OrderLink,
      QuotedPrice: newRow.QuotedPrice,
      SalePrice: newRow.SalePrice,
      ChinaCost: newRow.ChinaCost,
      LocalDeliveryFee: newRow.LocalDeliveryFee,
      KoreaCost: newRow.KoreaCost,
      DeliveryPrice: newRow.DeliveryPrice,
      RocketMargin: newRow.RocketMargin,
      OurMargin: newRow.OurMargin,
      TotalCost: newRow.TotalCost,
      AccountingCost: newRow.AccountingCost,
      SupplierCompany: newRow.SupplierCompany,
      VAT: newRow.VAT,
    };
    updateProduct(data);
    return updatedRow;
  };

  const brandCodeToImage = React.useMemo(() => {
    const result: {
      [key: string]: {
        imagePath: string;
        brandName: string;
      };
    } = {};

    brandIconOptions.forEach((v) => {
      result[v.brandCode] = {
        imagePath: v.imagePath,
        brandName: v.brandName,
      };
    });

    return result;
  }, [brandIconOptions]);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "BarCode",
      headerName: "바코드",
      type: "string",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        const { BarCode } = row;
        return <span>{BarCode}</span>;
      },
    },
    {
      field: "ProductName",
      editable: true,
      headerName: "상품명",
      minWidth: 250,
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { ProductName } = row;
        return (
          <span
            style={{
              //  textDecoration: "underline",
              whiteSpace: "normal",
              wordBreak: "keep-all",
              overflowWrap: "break-word",
            }}
          >
            {ProductName}
          </span>
        );
      },
    },
    {
      field: "Memo0",
      editable: true,
      headerName: "자동상품메모",
      minWidth: 200,
      flex: 1,
      align: "left",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Memo0 } = row;
        return (
          <div
            style={{
              // textDecoration: "underline",
              padding: "0px",
              margin: "0px",
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <pre>{Memo0}</pre>
          </div>
        );
      },
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "Memo00",

      editable: true,
      headerName: "메모",
      minWidth: 200,
      flex: 1,
      align: "left",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Memo00 } = row;
        return (
          <div
            style={{
              // textDecoration: "underline",
              padding: "0px",
              margin: "0px",
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <pre>{Memo00}</pre>
          </div>
        );
      },
      renderEditCell: (params) => <EditTextarea {...params} />,
    },
    {
      field: "Season",
      headerName: "시즌",
      width: 100,
      editable: true,
      align: "center",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: seasonOptions.map((season) => season.value),
    },
    {
      field: "Color",
      editable: true,
      headerName: "색상",
      width: 100,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Color } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {Color}
          </span>
        );
      },
    },
    {
      field: "Size",
      editable: true,
      headerName: "사이즈",
      width: 100,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { Size } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {Size}
          </span>
        );
      },
    },
    {
      field: "Brand",
      editable: true,
      headerName: "브랜드",
      width: 120,
      //flex: 1,
      align: "center",
      cellClassName: "cell-divider",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return (
          <RowStack>
            <img src={brandCodeToImage[row.Brand].imagePath} alt={row.Brand} style={{ width: "30px", height: "30px" }} />
            <span>{brandCodeToImage[row.Brand].brandName}</span>
          </RowStack>
        );
      },
      renderEditCell: ({ api, row, id, field, status }: any) => {
        return <BrandEditCell status={status} row={row} api={api} id={id} field={field} />;
      },
    },
    {
      field: "TotalCount",
      editable: true,
      headerName: "재고",
      width: 80,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { TotalCount, BarCode } = row;
        return <ShowInventoryButton BarCode={BarCode} TotalCount={TotalCount || 0} status={status} />;
      },
    },
    {
      field: "Status",
      headerName: "상태",
      editable: true,

      width: 80,
      //flex: 1,
      align: "center",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: STATE_OPTION,
      renderCell: ({ row }) => {
        const color = row.Status === "메인" ? "primary" : row.Status === "도매" ? "success" : "error";
        return <Chip variant="outlined" color={color} label={row.Status} />;
      },
    },
    {
      field: "SKU_ID",
      editable: true,
      headerName: "SKU ID",
      width: 120,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: (params) => {
        const skuId = params.row.SKU_ID;
        return <span>{skuId ? skuId : "-"}</span>;
      },
    },

    {
      field: "SupplierCompany",
      editable: true,
      headerName: "거래처",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: (params) => {
        const buy = params.row.SupplierCompany;
        return <span>{buy ? buy : "-"}</span>;
      },
    },

    {
      field: "OrderLink",
      editable: true,
      headerName: "주문링크",
      width: 200,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const OrderLink = row.OrderLink;
        const handleClick = () => window.open(OrderLink, "_blank");

        return (
          <Typography variant="caption" sx={{ position: "relative" }}>
            {OrderLink}
            {OrderLink && (
              <Tooltip placement="right" arrow title={<p>링크로 이동합니다.</p>}>
                <IconButton
                  sx={{ position: "absolute", top: -5, right: 0, backgroundColor: "white", border: "1px solid #dddddd", "&hover": "none" }}
                  onClick={handleClick}
                >
                  <OpenInNewIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        );
      },
    },
    //--------------------------------------------------------

    {
      field: "QuotedPrice",
      editable: true,
      headerName: "견적가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { QuotedPrice } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(QuotedPrice).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "SalePrice",
      editable: true,
      headerName: "판매가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { SalePrice } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(SalePrice).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "ChinaCost",
      editable: true,
      headerName: "중국원가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { ChinaCost } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(ChinaCost).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "LocalDeliveryFee",
      editable: true,
      headerName: "현지배송비",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { LocalDeliveryFee } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(LocalDeliveryFee).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "KoreaCost",
      editable: true,
      headerName: "한국원가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { KoreaCost } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(KoreaCost).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "TotalCost",
      editable: true,
      headerName: "총원가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { TotalCost } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(TotalCost).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "DeliveryPrice",
      editable: true,
      headerName: "납품가",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { DeliveryPrice } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(DeliveryPrice).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "VAT",
      editable: true,
      headerName: "부가세",
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { VAT } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(VAT).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "RocketMargin",
      headerName: "로켓마진",
      editable: true,
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { RocketMargin } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(RocketMargin).toLocaleString()}
          </span>
        );
      },
    },
    {
      field: "AccountingCost",
      headerName: "회계원가",
      editable: true,
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { AccountingCost } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(AccountingCost).toLocaleString()}
          </span>
        );
      },
    },

    {
      field: "OurMargin",
      headerName: "자사마진",
      editable: true,
      width: 80,
      align: "center",
      headerAlign: "center",
      type: "string",
      renderCell: ({ row }) => {
        const { OurMargin } = row;
        return (
          <span
          // style={{ textDecoration: "underline" }}
          >
            {parseInt(OurMargin).toLocaleString()}
          </span>
        );
      },
    },
  ];

  const handleSortChange = (e: any) => {
    setParams((prev) => ({
      ...prev,
      sortby: e[0]?.field || "",
      direction: e[0]?.sort || "",
    }));
  };

  const handleDeleteProduct = async () => {
    const dtoList = selected.map((id) => (rows?.results[id] as any).BarCode);
    if (confirm(`선택한 ${dtoList.length}개의 상품을 삭제하시겠습니까?\n이 작업은 되돌릴 수 없습니다.`)) {
      for (const dto of dtoList) {
        await deleteProduct(dto);
      }
    }
  };

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const [statusModalOpen, setStatusModalOpen] = React.useState(false);
  const handleChangeStatusModalOpen = () => {
    setStatusModalOpen(!statusModalOpen);
  };

  const handleBulkUpdateProductStatus = (status: statusType) => {
    const dtoList = selected.map((id) => (rows?.results[id] as any).BarCode);
    dtoList.forEach((dto) => {
      updateProduct({
        BarCode: dto,
        Status: status,
      });
    });
  };

  return (
    <>
      <CustomTableContainer>
        <RowStack gap={2} sx={{ mb: 2, backgroundColor: "white", px: 4, height: "70px", border: "1px solid #dfdfdf" }}>
          <SearchInputField value={params.searchText} id="searchText" onChange={handleChangeParams} />
          <SelectForm
            handleChange={handleChangeParams}
            id="status"
            value={params.status}
            options={[{ label: "전체", value: "" }, ...statusOptions]}
            sx={{ width: "100px" }}
            label="상태"
            size="small"
          />
          <SelectForm
            handleChange={handleChangeParams}
            id="brand"
            value={params.brand.toLocaleUpperCase()}
            options={[{ label: "전체", value: "" }, ...brandOptions]}
            sx={{ width: "150px" }}
            label="브랜드"
            size="small"
          />
          {selected.length !== 0 && DELEABLE_ROLD_ID.includes(RoleID) && (
            <Button color="primary" size="large" onClick={handleOpenMenu} startIcon={<ChecklistIcon />}>
              선택한 상품 [{selected.length}]
            </Button>
          )}
          <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
            <MenuList>
              <MenuItem onClick={handleChangeStatusModalOpen}>
                <ListItemIcon>
                  <FlipCameraAndroidOutlinedIcon />
                </ListItemIcon>
                일괄 상태변경
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleDeleteProduct} sx={{ color: "red" }}>
                <ListItemIcon>
                  <DeleteOutlineIcon color="error" />
                </ListItemIcon>
                일괄삭제
              </MenuItem>
            </MenuList>
          </Menu>

          <FormControlLabel
            sx={{ ml: "auto" }}
            label="전체보기"
            control={<Switch checked={showColumnToggle} onChange={(e) => setShowColumnToggle(e.target.checked)} />}
          />
          <ExportToExcelButtonInProductTable params={params} />
          <UpdateProductButton />
          <CreateProductButton />
          <RefreshIconButton onClick={() => refetch()} />
        </RowStack>

        <DataGrid
          checkboxSelection={DELEABLE_ROLD_ID.includes(RoleID)}
          getRowHeight={() => "auto"}
          onSortModelChange={handleSortChange}
          sx={{
            ...dataGridStyle,
            [`& .MuiDataGrid-cell`]: {
              paddingTop: 1,
              paddingBottom: 1,
              lineHeight: "unset !important",
              maxHeight: "none !important",
              whiteSpace: "normal",
            },
          }}
          onRowSelectionModelChange={(selectIdList) => {
            setSelected(selectIdList as number[]);
          }}
          disableRowSelectionOnClick
          loading={isLoading || isFetching}
          rows={rows?.results ? rows.results : []}
          disableColumnSelector
          onColumnWidthChange={(e) => {
            console.log(e); // 컬럼사이즈 조정 가능 => 각각의 컬럼 하나만 생성됨
          }}
          columns={columns.map((item) => ({ ...item, cellClassName: "cell-divider common-cell" }))}
          rowModesModel={rowModesModel}
          disableColumnFilter
          disableDensitySelector
          columnVisibilityModel={showColumnToggle ? SHOW_COLUMN : HIDE_FILETER}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onCellEditStop={(params, event) => {
            if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
              return;
            }
            if (isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
              event.defaultMuiPrevented = true;
            }
          }}
          slots={{
            toolbar: GridToolbar,
            pagination: CustomPagination as any,
          }}
          slotProps={{
            pagination: {
              count: rows?.count || 0,
              page: params?.page || 1,
              pageSize: params?.page_size || 100,
              onChange: handleChangeParams,
            } as any,
            columnsManagement: {
              getTogglableColumns,
            },
            // toolbar: {
            //   setRowModesModel,
            //   ...otherToolbar,
            // },
          }}
        />
      </CustomTableContainer>
      <BulkStatusUpdateModal open={statusModalOpen} onClose={handleChangeStatusModalOpen} onSubmit={handleBulkUpdateProductStatus} />
    </>
  );
}
