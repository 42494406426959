import {
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  Stack,
  TextField,
  Button,
  DialogActions,
} from "@mui/material";
import React from "react";
// import SelectForm from "../../../../component/form/SelectForm";
import { createRackRequestDto } from "../../../../service/rack/types";
// import { flexCodeOptions } from "../../../../shared/const/flexCodeOptions";
// import { floorCodeOptions } from "../../../../shared/const/floorCodeOptions";
// import { rackDanCodeOptions } from "../../../../shared/const/rackDanCodeOptions";
// import { warehouseCodeOptions } from "../../../../shared/const/warehouseCodeOptions";
import { customToast } from "../../../../shared/lib/customToast";
import { ShowPreviewRackCode } from "./ShowPreviewRackCode";

//비즈니스 로직은 외부에서 가져옴
interface AddRackModalProps {
  open: boolean;
  handleSubmit: (data: createRackRequestDto) => void;
  handleClose: () => void;
}

export const CreateRackModal: React.FC<AddRackModalProps> = ({ open, handleSubmit, handleClose }) => {
  const generateRackNumber = () => {
    return rackData.warehouseCode + rackData.flexCode + rackData.floorCode + rackData.rackCode + rackData.rackDanCode;
  };
  const [rackNumber, setRackNumber] = React.useState("");

  const handleChangRackNumber = (e: any) => {
    const value = e.target.value;

    if (isNaN(value) && !!value) {
      customToast("warn", "숫자만 입력해주세요.");
      console.log(true);
    } else if (value.length > 7) {
      customToast("warn", "최대 7자리까지 입력가능합니다. ");
    } else {
      setRackNumber(value);
      console.log(false);
    }
  };

  const [rackData, setRackData] = React.useState({
    warehouseCode: "",
    floorCode: "",
    flexCode: "",
    rackCode: "",
    rackDanCode: "",
    coupangeRackID: "",
  });

  const [brandOptions, setBrandOptions] = React.useState([
    { selected: false, label: "페이퍼먼츠", value: "PA" },
    { selected: false, label: "아뗄릭", value: "AT" },
    { selected: false, label: "비비에모", value: "VI" },
    { selected: false, label: "메이첼", value: "ME" },
    { selected: false, label: "페이퍼슈즈", value: "SP" },
    { selected: false, label: "스크힐즈슈즈", value: "CS" },
    { selected: false, label: "맨즈건", value: "MA" },
    { selected: false, label: "애버민트", value: "EV" },
    { selected: false, label: "시크힐즈", value: "CH" },
    { selected: false, label: "혼합", value: "MX" },
  ]);

  const combineBrand = () => {
    return brandOptions.reduce((acc, option) => {
      if (option.selected) {
        if (acc !== "") {
          acc += ", ";
        }
        acc += option.value;
      }
      return acc;
    }, "");
  };

  const handleSelectBrand = (index: number) => {
    if (index !== 9) {
      brandOptions[index].selected = !brandOptions[index].selected;
    } else {
      //9 번째 혼합만 선택하고 나머지 selected는 false로 초기화
      brandOptions[index].selected = !brandOptions[index].selected;
      brandOptions.forEach((item, i) => {
        if (i !== index) {
          item.selected = false;
        }
      });
    }
    setBrandOptions([...brandOptions]);
  };

  const handleCreateRackSubmit = () => {
    if (
      // rackData.flexCode !== "" &&
      // rackData.floorCode !== "" &&
      // rackData.rackCode !== "" &&
      // rackData.rackDanCode !== "" &&
      // rackData.warehouseCode !== "" &&
      // !!combineBrand()
      rackNumber.length >= 4
    ) {
      // 랙코드 생성
      if (window.confirm(`[${rackNumber}] 랙을 추가하시겠습니까?`)) {
        // handleSubmit({
        //   RackID: generateRackNumber(),
        //   Brand: combineBrand(),
        //   CoupangRackID: rackData.coupangeRackID,
        //   IsCoupang: !!rackData.coupangeRackID,
        // });
        handleSubmit({
          RackID: rackNumber,
          Brand: "",
          CoupangRackID: "",
          IsCoupang: true,
        });
      }
    } else {
      customToast("warn", "최소 4자리 이상 입력해주세요.");
    }
  };

  const handleChangeRackData = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setRackData({
      ...rackData,
      [name]: value,
    });
  };

  const handleChangeRackCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    // 숫자만 입력되도록 정규식 처리, 3자리까지 입력되도록 slice 처리
    if (id !== "coupangeRackID") {
      setRackData({
        ...rackData,
        [id]: value.replace(/\D/g, "").slice(0, 3),
      });
    } else {
      setRackData({
        ...rackData,
        [id]: value,
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">랙 추가</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ width: "400px", mb: 4 }}>
            <Typography variant="subtitle2">
              랙 정보를 모두 입력해주세요.
              <br />
              {/* 브랜드는 중복선택이 가능합니다. 최소 1개이상 선택해주세요. */}
            </Typography>
            <ShowPreviewRackCode rackData={rackNumber} />
            {/* <ShowPreviewRackCode rackData={rackData} /> */}
          </DialogContentText>
          <Stack gap={2}>
            {/* <Stack direction="row" gap={1} justifyContent={"center"} sx={{ flexWrap: "wrap", maxWidth: "400px", mb: 1 }}>
              {brandOptions.map((item, i) => {
                if (item.value !== "MX") {
                  return (
                    <Chip
                      onClick={() => handleSelectBrand(i)}
                      disabled={brandOptions[9].selected}
                      label={item.label}
                      color={item.selected ? "primary" : "default"}
                      variant={item.selected ? "filled" : "outlined"}
                    />
                  );
                } else {
                  return (
                    <Chip
                      onClick={() => handleSelectBrand(i)}
                      label={item.label}
                      color={item.selected ? "primary" : "default"}
                      variant={item.selected ? "filled" : "outlined"}
                    />
                  );
                }
              })}
            </Stack>

            <SelectForm
              options={warehouseCodeOptions}
              id={"warehouseCode"}
              value={rackData.warehouseCode}
              handleChange={handleChangeRackData}
              label="사업장"
            />
            <SelectForm options={flexCodeOptions} id={"flexCode"} value={rackData.flexCode} handleChange={handleChangeRackData} label="공간" />
            <SelectForm options={floorCodeOptions} id={"floorCode"} value={rackData.floorCode} handleChange={handleChangeRackData} label="층" />

            <TextField
              label="랙진열 순서"
              id="rackCode"
              onChange={handleChangeRackCode}
              value={rackData.rackCode}
              placeholder="세자리번호 입력 ex) 123, 456, 789 등"
            />

            <SelectForm
              options={rackDanCodeOptions}
              id={"rackDanCode"}
              value={rackData.rackDanCode}
              handleChange={handleChangeRackData}
              label="단수"
            />
            <TextField label="쿠팡코드" id="coupangeRackID" onChange={handleChangeRackCode} value={rackData.coupangeRackID} placeholder="" />
            */}
            <TextField value={rackNumber} onChange={handleChangRackNumber} />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleCreateRackSubmit} autoFocus variant="contained">
            등록
          </Button>
          <Button onClick={handleClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
