import * as React from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridToolbar,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddUserButton from "./create-user-modal/CreateUserButton";
import { useDeleteUser, useGetUserList, useUpdateUser } from "../../../service/user/userHook";
import { deleteUserDto, getUserResponseDto, updateUserDto } from "../../../service/user/types";
import CustomPagination from "../../../widget/custom-pagination/ui/CustomPagination";
import { dataGridStyle, otherToolbar } from "../../../shared/style/mui-table-style/MuiTableStyle";
import { useUserInfoState } from "../../../store/UserInfoStore";
import TitleAndMenu from "../../../widget/title-and-menu/TitleAndMenu";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import { ADMIN_COLUMNS, NORMAL_COLUMNS } from "../const/columns";

export default function UserTable() {
  const { data: rows, refetch, isFetching } = useGetUserList();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({}); // 현재 변경중인 값
  const { mutate: deleteUser } = useDeleteUser();
  const { mutate: updateUser } = useUpdateUser();
  const { IsStaff } = useUserInfoState((s) => s);

  //편집 중지
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  //삭제 클릭
  const handleDeleteClick = (data: deleteUserDto) => () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteUser(data);
    }
  };

  const processRowUpdate = (newRow: GridRowModel & getUserResponseDto) => {
    const updatedRow = { ...newRow, isNew: false };
    const { UserID, ExpireTime } = updatedRow;
    const data: updateUserDto = { UserID, ExpireTime };
    updateUser(data);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "UserName",
      headerClassName: "super-app-theme--header",
      headerName: "이름",
      type: "string",
      minWidth: 100,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "RoleID",
      headerClassName: "super-app-theme--header",
      headerName: "권한", // 핸드폰 번호로 하는게 좋다.
      type: "string",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "password",
      headerClassName: "super-app-theme--header",
      headerName: "개인식별번호", // 핸드폰 번호로 하는게 좋다.
      type: "string",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "last_login",
      headerClassName: "super-app-theme--header",
      headerName: "최근접속일",
      minWidth: 200,
      flex: 1,
      // editable: true,
      align: "center",
      headerAlign: "center",
      type: "dateTime",
    },

    {
      field: "ExpireTime",
      headerClassName: "super-app-theme--header",
      headerName: "만료일",
      minWidth: 200,
      flex: 1,
      editable: IsStaff ? true : false,
      align: "center",
      type: "dateTime",
      renderCell: (params) => {
        return <span style={{ textDecoration: "underline" }}>{params.row.ExpireTime.toLocaleString()}</span>;
      },
      headerAlign: "center",
    },

    {
      field: "actions",
      headerClassName: "super-app-theme--header",
      type: "actions",
      align: "center",
      headerAlign: "center",
      headerName: "삭제",
      minWidth: 80,
      flex: 1,
      cellClassName: "actions",
      getActions: (params) => {
        const UserID = params.row.UserID;
        const UserName = params.row.UserName;

        return [<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick({ UserID, UserName })} color="inherit" />];
      },
    },
  ];

  return (
    <CustomTableContainer>
      <TitleAndMenu refetch={refetch} AddButton={IsStaff ? <AddUserButton /> : ""} title="유저 목록" />
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        sx={dataGridStyle}
        rows={rows ? rows : []}
        loading={isFetching}
        columns={columns.map((item) => ({ ...item, cellClassName: "cell-divider" }))}
        rowModesModel={rowModesModel}
        disableDensitySelector
        columnVisibilityModel={IsStaff ? ADMIN_COLUMNS : NORMAL_COLUMNS}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: GridToolbar,
          pagination: CustomPagination,
        }}
        slotProps={{
          toolbar: { setRowModesModel, ...otherToolbar },
        }}
      />
    </CustomTableContainer>
  );
}
