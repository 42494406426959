import { Button, Card, Collapse, Divider, List, ListItemButton, ListItemText, Stack, Tooltip, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { BACKGROUND_COLOR } from "../../../pages/Login";
import { BrandIconType, brandIconOptions } from "../const/brandIconsOption";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useLogout } from "../../../service/user/userHook";
import { useBrandCodeStore } from "../../../store/brandStore";
import { getUserInfo, useUserInfoState } from "../../../store/UserInfoStore";
import { nav_items2, NavItem } from "../../../config/nav-config";
import { SIDENAV_WIDTH } from "../../../shared/const/constant";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { MODE } from "../../../config/routes-config";
import { useBoxNameStore } from "../../../store/boxNameStore";
import { ChangeBrandButton } from "./change-brand-modal/ChangeBrandButton";
import Modals from "../../../widget/modals/ui/Modals";
import SystemUpdateOutlinedIcon from "@mui/icons-material/SystemUpdateOutlined";
import { customToast } from "../../../shared/lib/customToast";
import RowStack from "../../../shared/ui/RowStack";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

//사이드바 크기

// const APP_VERSION = "2024년 11월 4일 버전";
const APP_FILE_NAME = "paperlife_20241104.zip";
const APP_VERSION = `${APP_FILE_NAME.split("_")[1].slice(0, 4)}년 ${APP_FILE_NAME.split("_")[1].slice(4, 6)}월 ${APP_FILE_NAME.split("_")[1].slice(
  6,
  8
)}일 업데이트`;

const SideNav: React.FC = () => {
  const navigate = useNavigate();
  const { mutate: logout } = useLogout();
  const [selected, setSelected] = useState<number>(-1);
  const { pathname } = useLocation();
  const BrandCode = useBrandCodeStore((state) => state.BrandCode);
  const { clearBoxInfo } = useBoxNameStore((state) => state);
  const { RoleID, UserName } = useUserInfoState((v) => v);

  useEffect(() => {
    if (!UserName) {
      handleLogout();
    }
  }, [pathname]);

  const handleLogout = () => {
    logout();
    clearBoxInfo();
  };

  const brandMap: Map<string, BrandIconType> = useMemo(() => {
    return new Map(brandIconOptions.map((item) => [item.brandCode, item]));
  }, []);

  const handleAppDwonload = () => {
    customToast("info", "페투앱 다운로드");
    window.location.href = `https://paperlife.kr/template/${APP_FILE_NAME}`;
  };

  return (
    <Stack
      component="nav"
      justifyContent={"space-between"}
      sx={{
        backgroundColor: BACKGROUND_COLOR,
        minWidth: SIDENAV_WIDTH,
        width: SIDENAV_WIDTH,
        p: 1,
        borderRight: "1px solid #e0e0e0",
        minHeight: "100vh",
        height: "100%",
        boxSizing: "border-box",
      }}
    >
      {/* 제목 */}
      <Stack component="div" gap={1}>
        <Stack sx={{ pl: 2, mt: 4, mb: 2 }} gap={2}>
          <Stack>
            <Typography fontSize={"22px"} color="primary" sx={{ lineHeight: 0.8, mb: 0.5, letterSpacing: "2px" }} fontWeight={"bold"}>
              PAPERLIFE
            </Typography>
            <Typography fontSize={"18px"}>재고관리시스템</Typography>
          </Stack>
        </Stack>

        {/* 전체 */}
        <Divider sx={{ my: 2 }} />

        <Stack direction="row" alignItems="center" gap={2} sx={{ p: 2 }}>
          <AccountCircleOutlinedIcon color="primary" />
          <Typography variant="caption">{UserName}</Typography>
        </Stack>
        <Divider sx={{ my: 2 }} />

        {/* 새로운 사이드바 */}
        <List>
          {nav_items2
            .filter((v: any) => {
              if (MODE === "PROV" && v.title === "개발") return false; // 개발자 페이지는 개발자 모드에서만 보이도록
              if (RoleID !== 100 && v.title === "매출") return false; // 개발자 및 대표님만 매출 페이지 보이도록

              return true;
            })
            .map((item: NavItem, i: number) => {
              return item.children ? (
                <React.Fragment key={item.title}>
                  <ListItemButton onClick={() => setSelected((prev) => (prev === i ? -1 : i))} sx={{ fontSize: 3 }}>
                    <ListItemText
                      primary={item.title}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.8rem", // primary 텍스트의 폰트 크기 설정
                        },
                      }}
                    />
                    {selected === i ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={selected === i} timeout="auto" unmountOnExit>
                    <Divider />
                    <List sx={{ p: 0, backgroundColor: selected === i ? "#f5f5f5" : "white" }}>
                      {item?.children?.map((item: NavItem, i: number) => {
                        return (
                          <ListItemButton
                            key={`${item.title}-${i}`}
                            onClick={() => navigate(`/${item.link}`)}
                            sx={{ color: pathname.split("/")[1] === item.link ? "primary.main" : "default", p: "5px", pl: 6 }}
                          >
                            <ArrowRightIcon sx={{ mr: 2 }} />
                            <ListItemText
                              primary={item.title}
                              sx={{
                                "& .MuiListItemText-primary": {
                                  fontSize: "0.8rem", // primary 텍스트의 폰트 크기 설정
                                },
                              }}
                            />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              ) : (
                <React.Fragment key={item.title}>
                  <ListItemButton
                    onClick={() => navigate(`/${item.link}`)}
                    sx={{ color: pathname.split("/")[1] === item.link ? "primary.main" : "default", fontSize: 3 }}
                  >
                    <ListItemText
                      primary={item.title}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.8rem", // primary 텍스트의 폰트 크기 설정
                        },
                      }}
                    />
                  </ListItemButton>
                </React.Fragment>
              );
            })}
        </List>
      </Stack>
      <Stack gap={1}>
        <Tooltip title={<Typography variant="subtitle2">{APP_VERSION}</Typography>} arrow placement="top">
          <Button startIcon={<SystemUpdateOutlinedIcon />} onClick={handleAppDwonload}>
            {" "}
            앱 다운로드{" "}
          </Button>
        </Tooltip>
        <Button onClick={handleLogout} sx={{ height: "50px", color: "text.secondary" }} startIcon={<LogoutIcon />}>
          로그아웃
        </Button>
      </Stack>

      {/* 모든 모달을 생성, 관리하는 컴포넌트 */}
      <Modals />
    </Stack>
  );
};

export default SideNav;
