import React, { useEffect, useMemo, useState } from "react";
import * as XLSX from "xlsx";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import SystemUpdateAltOutlinedIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import { brandIconOptions, brandOptions } from "../../side-navigation/const/brandIconsOption";
import { getCurrentDateTime } from "../../individual-order/lib/getCurrentDateTime";
import { newProductRequestDto } from "../../../service/product/types";
import { productApi } from "../../../service/product/productAxios";
import { initialState } from "./NewProductTable";
import SelectForm from "../../../widget/form/ui/SelectForm";
import { statusOptions } from "../../../shared/const/statusOptions";

interface ExportExcelButtonProps {
  params: newProductRequestDto;
}

const ExportToExcelButtonInProductTable: React.FC<ExportExcelButtonProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [params, setParams] = React.useState<newProductRequestDto>({ ...initialState });
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setParams({ ...initialState });
  }, []);

  const handleChangeParams = (e: any) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.trim().toLocaleLowerCase() : value;
    setParams((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  //브랜드 이름
  const brandMap = useMemo(() => {
    return brandIconOptions.reduce((acc: Record<string, string>, cur) => {
      const { brandCode, brandName } = cur;
      acc[brandCode] = brandName; // 값을 누적
      return acc; // 누적된 객체 반환
    }, {});
  }, [brandIconOptions]);

  //파일명
  const fileName = `[${getCurrentDateTime()}][${brandMap[params.brand.toUpperCase()]}] 상품목록`;

  //엑셀 다운로드 함수 => 따로 작성한 이유는 숫자때문
  const exportToExcel = () => {
    productApi.getNewProductList({ ...params, no_pagination: "true" }).then((data: any) => {

      if (data.length === 0) return;
      const formattedData = data.map((item: any, index: number) => ({
        No: index,
        바코드: item.BarCode,
        상품명: item.ProductName || "",
        자동상품메모: item.Memo0 || "",
        메모: item.Memo0 || "",
        시즌: item.Season || "",
        색상: item.Color || "",
        사이즈: item.Size || "",
        // 브랜드: brandMap[item.Brand] || "",
        재고: item.TotalCount || 0,
        SKU_ID: item.SKU_ID || "",
        거래처: item.SupplierCompany || "",
        주문링크: item.OrderLink || "",
        견적가: Math.round(parseInt(item.QuotedPrice) || 0),
        판매가: Math.round(parseInt(item.SalePrice) || 0),
        중국원가: Math.round(parseInt(item.ChinaCost) || 0),
        현지배송비: Math.round(parseInt(item.LocalDeliveryFee) || 0),
        한국원가: Math.round(parseInt(item.KoreaCost) || 0),
        총원가: Math.round(parseInt(item.TotalCost) || 0),
        납품가: Math.round(parseInt(item.DeliveryPrice) || 0),
        부가세: Math.round(parseInt(item.VAT) || 0),
        로켓마진: Math.round(parseInt(item.RocketMargin) || 0),
        회계원가: Math.round(parseInt(item.AccountingCost) || 0),
        자사마진: Math.round(parseInt(item.OurMargin) || 0),
      }));

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "상품목록");

      const safeFileName = `${fileName}.xlsx`;
      XLSX.writeFile(workbook, safeFileName);
    });
    handleChangeOpen();
  };

  return (
    <>
      <Button variant="contained" size="large" onClick={handleChangeOpen} startIcon={<SystemUpdateAltOutlinedIcon />}>
        엑셀 다운로드
      </Button>

      <Dialog open={open} onClose={handleChangeOpen}>
        <DialogTitle>엑셀 다운로드</DialogTitle>

        <DialogContent>
          <Stack gap={2} sx={{ mt: 5 }}>
            <SelectForm
              handleChange={handleChangeParams}
              id="status"
              value={params.status}
              options={statusOptions}
              sx={{ width: "100%" }}
              label="상태"
            />
            <SelectForm
              handleChange={handleChangeParams}
              id="brand"
              value={params.brand.toLocaleUpperCase()}
              options={brandOptions}
              sx={{ width: "100%" }}
              label="브랜드"
            />

            <Button
              variant="contained"
              disabled={!params.brand || !params.status}
              sx={{ width: "250px", height: "80px" }}
              onClick={exportToExcel}
              startIcon={<SystemUpdateAltOutlinedIcon />}
            >
              엑셀 다운로드
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleChangeOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportToExcelButtonInProductTable;
