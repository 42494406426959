import { Button, Dialog, DialogTitle, DialogContent, Typography, Divider, DialogActions, MenuItem, ListItemIcon } from "@mui/material";
import { useState } from "react";
import { useUpdateCustomerFindOrder } from "../../../../service/customer-find-order/customerFindOrderHooks";
import SelectForm from "../../../../widget/form/ui/SelectForm";
import SettingsIcon from "@mui/icons-material/Settings";
import { customerOrderStatus } from "../../const/customerOrderStatus";
interface StatusChangeButtonProps {
  selected: number[];
  onCashChange: (data: any) => any;
}

const StatusChangeButton: React.FC<StatusChangeButtonProps> = ({ selected, onCashChange }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const { mutate: updateCustomerFindOrder } = useUpdateCustomerFindOrder((data: any) => {
    onCashChange((prev: any) =>
      prev.map((item: any) => {
        if (selected.includes(item.CustomerOrderID)) {
          return { ...item, StatusMeno: status, FinishDate: "" };
        } else {
          return item;
        }
      })
    );
  });

  //실제로 오타가 나있어서 여기서도 Meno라고함
  const handleChangeStatusMeno = () => {
    const data = selected.map((CustomerOrderID) => {
      return {
        CustomerOrderID,
        StatusMeno: status,
        Status: "R",
        FinishDate: null,
      };
    });

    updateCustomerFindOrder(data);
    handleChageOpen();
  };

  const handleChageOpen = () => {
    setOpen(!open);
  };

  const handleChangeStatus = (e: any) => {
    setStatus(e.target.value);
  };

  return (
    <>
      <MenuItem onClick={handleChageOpen}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        일괄 상태변경
      </MenuItem>

      <Dialog open={open} onClose={handleChageOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">상품별 준비 및 확정수량</DialogTitle>
        <DialogContent sx={{ width: "200px" }}>
          <Typography fontWeight="bold" fontSize="18px">
            일괄 상태변경
          </Typography>
          <Divider sx={{ my: 2 }} />
          <SelectForm
            value={status}
            label="상태메모 일괄변경"
            handleChange={handleChangeStatus}
            options={customerOrderStatus.map((v) => ({ label: v.label, value: v.value }))}
          />
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleChangeStatusMeno} variant="contained">
            등록
          </Button>
          <Button onClick={handleChageOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatusChangeButton;
