import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createStoreResponseDto,
  getStoreListRequestDto,
  getStoreListResponseDto,
  getStoreRequestDto,
  getStoreResponseDto,
  updateStoreDto,
  updateStoreItemDto,
} from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { storeApi } from "./storeAxios";
import { createStoreRequestDto } from "./types";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { Id } from "./types";

export const useGetStore = (data: getStoreRequestDto) => {
  return useQuery<getStoreResponseDto, Error>({
    queryKey: [QUERY_KEY.store, data],
    queryFn: () => storeApi.getStore(data),
  });
};

export const useGetStoreList = () => {
  return useQuery<getStoreListRequestDto, Error, (getStoreListResponseDto & Id)[]>({
    queryKey: [QUERY_KEY.storeList],
    queryFn: () => storeApi.getStoreList(),
  });
};
/*
export const useCreateStore = () => {
  const queryClient = useQueryClient();
  return useMutation<createStoreResponseDto, Error, createStoreRequestDto>({
    mutationFn: (data: createStoreRequestDto) => storeApi.createStore(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.storeList] });
      console.log("post 성공", data);
    },
    onError: (err) => {
      window.alert("입고서 양식에 맞지 않습니다.");
      displayErrorMessage({ defaultErrorMessage: "압고서 생성에 실패했습니다.", error: err });
    },
  });
};
*/

export const useCreateStoreList = () => {
  const queryClient = useQueryClient();
  return useMutation<createStoreResponseDto[], Error, createStoreRequestDto[]>({
    mutationFn: (data: createStoreRequestDto[]) => storeApi.createStoreList(data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.storeList] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "압고서 생성에 실패했습니다.", error: err });
    },
  });
};

export const useUpdateStore = () => {
  const queryClient = useQueryClient();
  return useMutation<updateStoreDto, Error, updateStoreDto>({
    mutationFn: (data: updateStoreDto) => storeApi.updateStore(data),
    onSuccess: (data) => {
      queryClient.setQueryData([QUERY_KEY.store], (oldData: getStoreResponseDto[] | undefined) => {
        if (oldData) {
          return oldData.map((store) => {
            //데이터 타입에 맞춰서 변경

            if (store.IncomingOrderID === data.IncomingOrderID) {
              return {
                ...store,
                //업데이트할 데이터 지정
                Status: data?.Status || store.Status,
                ExpectedDate: data?.ExpectedDate || store.ExpectedDate,
              };
            }
            return store;
          });
        }
        return [];
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.store] });
      console.log("store 업데이트 성공");
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "입고서 업데이트에 실패하였습니다.", error: err });
    },
  });
};

export const useUpdateStoreItem = () => {
  const queryClient = useQueryClient();
  return useMutation<updateStoreItemDto, Error, updateStoreItemDto>({
    mutationFn: (data: updateStoreItemDto) => storeApi.updateStoreItem(data),
    onSuccess: (res) => {
      const { IncomingOrderID, ...data } = res;

      const var1 = queryClient.getQueryData([QUERY_KEY, IncomingOrderID]) as getStoreResponseDto;
      queryClient.setQueryData([QUERY_KEY.store, IncomingOrderID], (oldData: getStoreResponseDto | undefined) => {
        let list = oldData?.items || [];
        list = list
          ? list.map((item) => {
              if (item.IncomingOrderItemID === data.IncomingOrderItemID) {
                const result = { ...item, ...data };
                return result;
              }
              return item;
            })
          : [];

        return { ...oldData, items: list };
      });
    },
    onError: (err: Error) => {
      displayErrorMessage({ defaultErrorMessage: "입고 쿠팡일자 업데이트를 실패하였습니다.", error: err });
    },
  });
};
