import { Button, Dialog, DialogTitle, DialogContent, Typography, Divider, DialogActions, MenuItem, ListItemIcon, TextField } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { QUERY_KEY } from "../../../../config/querykey";
import { useUpdateCustomerFindOrder } from "../../../../service/customer-find-order/customerFindOrderHooks";
import { getCustomerOrderListResponseDto } from "../../../../service/customer-find-order/types";
import { useCustomerBrandStore } from "../../../../store/customerBrandStore";
import ChecklistIcon from "@mui/icons-material/Checklist";
interface StatusChangeButtonProps {
  selected: number[];
}

const DeliveryCompleteButton: React.FC<StatusChangeButtonProps> = ({ selected }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);
  const [date, setDate] = useState<string>("");
  const { brandStr, startDate, endDate } = useCustomerBrandStore((v) => v);
  const { mutate: updateCustomerFindOrder } = useUpdateCustomerFindOrder((data: any) => {
    queryClient.setQueryData([QUERY_KEY.customerFindOrder, brandStr, startDate, endDate], (oldData: getCustomerOrderListResponseDto[]) => {
      return oldData
        ? oldData.map((item) => {
            if (selected.includes(item.CustomerOrderID)) {
              return { ...item, StatusMeno: "5.발송완료", FinishDate: date, Status: "E" };
            } else {
              return item;
            }
          })
        : [];
    });
  });

  //실제로 오타가 나있어서 여기서도 Meno라고함
  const handleChangeStatusMeno = () => {
    const data = selected.map((CustomerOrderID) => {
      return {
        CustomerOrderID,
        StatusMeno: "5.발송완료",
        FinishDate: date,
        Status: "E",
      };
    });

    updateCustomerFindOrder(data);
    handleChageOpen();
  };

  const handleChageOpen = () => {
    setOpen(!open);
  };

  const handleChangeDate = (e: any) => {
    setDate(e.target.value);
  };

  return (
    <>
      <MenuItem onClick={handleChageOpen}>
        <ListItemIcon>
          <ChecklistIcon />
        </ListItemIcon>
        일괄 발송완료
      </MenuItem>

      <Dialog open={open} onClose={handleChageOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">일괄 발송완료</DialogTitle>
        <DialogContent sx={{ width: "200px" }}>
          <Typography fontWeight="bold" fontSize="18px">
            발송완료 날짜 선택
          </Typography>
          <Divider sx={{ my: 2 }} />
          <TextField value={date} type="date" onChange={handleChangeDate} fullWidth />
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleChangeStatusMeno} variant="contained" disabled={date === ""}>
            등록
          </Button>
          <Button onClick={handleChageOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeliveryCompleteButton;
