import DataGrid, { CellClickArgs } from "react-data-grid";
import { useState, useEffect, useCallback } from "react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteOrder, useGetOrderList } from "../../../service/order/orderHooks";
import _ from "lodash";
import "../style/OrderListTable.css";
import { CenterCellRander } from "../../total-order/style/style";
import { Chip, IconButton, Pagination, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useBrandCodeStore } from "../../../store/brandStore";
import SelectForm from "../../../widget/form/ui/SelectForm";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import { SortType } from "../../../shared/type/SortType";
import { Column, Row } from "../types/types";
import { statusOptions } from "../const/statusOptions";
import { CreateOrderButton } from "./create-order-modal/CreateOrderButton";
import { customToast } from "../../../shared/lib/customToast";
import { useOrderStatusStore } from "../../../store/orderStatus";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import { useLocalStorage } from "../../../service/localStorage/useLocalStorage";
import RowStack from "../../../shared/ui/RowStack";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import RefreshIconButton from "../../../shared/ui/buttom/RefreshIconButton";
import { UploadChangeBrandButton } from "../../product/ui/upload-change-brand-button/UploadChangeBrandButton";

const PAGE_SIZE = 20;

/// 발주서 목록 테이블

export const ORDER_LIST_TABLE_COLUMNS_VALUES = [570, 120, 120, 120, 120, 120, 120, 120, 120, 120];

export default function OrderListTable() {
  const BrandCode = useBrandCodeStore((s) => s.BrandCode);
  const [filter, setFilter] = useState("");
  const [widthList, setWidthList] = useLocalStorage("ATTERLIC_ORDER_LIST_TABLE", ORDER_LIST_TABLE_COLUMNS_VALUES);
  const { status, setStatus } = useOrderStatusStore((v) => v);
  const { data: rows, refetch, isFetching } = useGetOrderList({ brand: BrandCode, status: status === "전체" ? "" : status });
  const navigate = useNavigate();
  const [sortType, setSortType] = useState<SortType>({ columnKey: "ReceiveDate", direction: "ASC" });
  const { mutate: deleteOrder } = useDeleteOrder({ BrandCode, status });

  const handleChangeStatus = (e: SelectChangeEvent) => {
    setStatus(e.target.value as "E" | "R" | "전체");
  };

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType((prev) => ({ ...prev, direction: prev.direction === "ASC" ? "DESC" : "ASC" }));
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //정렬 상태 변경
  const sortedRows = useMemo(() => {
    if (!rows) return [];
    if (!sortType) return rows;
    const { columnKey, direction } = sortType;

    const firstRow = rows.filter((v) => v.OrderAlias.includes("사전확정"));

    const sortFunc = (a: any, b: any) => {
      const aValue = String(a[columnKey]) || "";
      const bValue = String(b[columnKey]) || "";

      if (aValue > bValue) {
        return direction === "ASC" ? 1 : -1;
      } else if (aValue < bValue) {
        return direction === "ASC" ? -1 : 1;
      }
      return 0;
    };

    if (firstRow.length === 1) {
      return [...firstRow, ...[...rows].filter((v) => !v.OrderAlias.includes("사전확정")).sort(sortFunc)];
    } else {
      return [...rows].filter((v) => !v.OrderAlias.includes("사전확정")).sort(sortFunc);
    }
  }, [rows, sortType]);

  //해당 발주서 id 클릭시 개별 발주서 페이지로 이동
  const handleCellClick = (args: CellClickArgs<Row, unknown>, event: React.MouseEvent<HTMLDivElement>) => {
    const { column, row } = args;
    if (column.key === "OrderAlias") {
      navigate(`/individualorder/${row.OrderID}`);
    }
  };

  const columns: Column[] = [
    {
      key: "OrderAlias",
      name: "발주서 별명",
      width: 570,
      sortable: true,
      editable: true,
      headerCellClass: "blue-header",
      renderCell: (params) => {
        const { OrderAlias, IsFlex } = params.row;

        return (
          <Stack
            flexDirection="row"
            alignItems={"center"}
            sx={{ height: "100%", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
            gap={2}
          >
            <Typography fontSize={"12px"} sx={{ color: "#333" }}>
              {OrderAlias}
            </Typography>
            {IsFlex ? <Chip size="small" label="flex" color="primary" variant="outlined" /> : null}
          </Stack>
        );
      },
    },
    {
      key: "Brand",
      name: "브랜드",
      headerCellClass: "blue-header",
      width: 120,
      sortable: true,
      renderCell: (p) => {
        return <CenterCellRander>{p.row.Brand}</CenterCellRander>;
      },
    },

    {
      key: "OrderDate",
      name: "발주일",
      width: 120,
      sortable: true,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return <CenterCellRander>{p.row.OrderDate}</CenterCellRander>;
      },
    },
    {
      key: "ReceiveDate",
      name: "입고일",
      width: 120,
      sortable: true,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return <CenterCellRander>{p.row.ReceiveDate}</CenterCellRander>;
      },
    },
    {
      key: "Center",
      name: "센터",
      width: 120,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return <CenterCellRander>{p.row.Center}</CenterCellRander>;
      },
    },
    {
      key: "sumQuantity",
      name: "발주수량",
      width: 120,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return <CenterCellRander>{p.row.sumQuantity.toLocaleString()}</CenterCellRander>;
      },
    },
    {
      key: "sumQuantityOK",
      name: "확정수량",
      width: 120,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return <CenterCellRander>{p.row.sumQuantityOK.toLocaleString()}</CenterCellRander>;
      },
    },
    {
      key: "sumQuantityReady",
      name: "준비수량",
      width: 120,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return <CenterCellRander>{p.row.sumQuantityReady.toLocaleString()}</CenterCellRander>;
      },
    },
    {
      key: "Status",
      name: "상태",
      width: 120,
      headerCellClass: "blue-header",
      renderCell: (p) => {
        return (
          <CenterCellRander>
            {p.row.Status === "R" ? <Chip label="준비" variant="outlined" color="primary" /> : <Chip label="완료" />}
          </CenterCellRander>
        );
      },
    },
    {
      key: "delete",
      name: "삭제",
      width: 120,
      headerCellClass: "blue-header",
      renderCell: ({ row }: any) => {
        const isPreConfirm = row.OrderAlias.includes("사전확정");
        const isZero = row.sumQuantityReady === 0;

        const isDeletable = !isPreConfirm && isZero;
        if (isPreConfirm) return <></>;

        return (
          <IconButton
            sx={{ width: "100%" }}
            onClick={() => {
              if (isDeletable && confirm("삭제하면 다시는 복구할 수 없습니다. 정말 삭제하시겠습니까?")) {
                const text = prompt(`삭제하시려면 [${row.OrderAlias}] 을/를 입력해주세요.`);
                if (text === row.OrderAlias) {
                  deleteOrder({ OrderID: row.OrderID });
                } else {
                  customToast("warn", "입력하신 발주서 별명이 일치하지 않습니다.");
                }
              } else {
                customToast("warn", "준비수량이 0 이상인 발주서는 삭제할 수 없습니다. ");
              }
            }}
          >
            <DeleteOutlineIcon color={isDeletable ? "primary" : "disabled"} />
          </IconButton>
        );
      },
    },
  ];


  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateCustomerInfo = async (newData: any, oldData: any) => {
    /**
     * newData : 새롭게 변경된 row
     * oldData : 변경되기 전 row
     */
  };

  //발주서 종류에 따라 row 색변경하기
  const handleChangeRowColor = (row: any) => {
    return row.IsFlex ? (row.OrderAlias.includes("사전확정") ? "pre-cell" : "flex-cell") : undefined;
  };

  const [currentPage, setCurrentPage] = useState(1);

  const filteredRows = useMemo(() => {
    if (!rows) return [];

    // 필터 적용
    const filtered = sortedRows.filter((row) => {
      if (!filter) return true;
      return Object.values(row).some((value) => String(value).includes(filter));
    });

    // 페이지네이션 적용
    const start = (currentPage - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    return filtered.slice(start, end);
  }, [filter, sortedRows, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rows]);

  const handleColumnResize = useCallback(
    _.throttle((index, width) => {
      setWidthList((prev: number[]) => {
        const newWidthList = [...prev];
        newWidthList[index] = width;
        return newWidthList;
      });
    }, 400),
    []
  );

  return (
    <>
      <BackDropLoading open={isFetching} />
      <CustomTableContainer>
        <RowStack gap={1} sx={{ mb: 2 }}>
          <UploadChangeBrandButton sx={{ width: "200px", backgroundColor: "white", height: "46px", borderRadius: "0px" }} />
          <RowStack gap={3} sx={{ px: 4, py: 3, backgroundColor: "white", border: "1px solid #dfdfdf", width: "100%" }}>
            <div></div>
            <SearchInputField value={filter} onChange={(e) => setFilter(e.target.value.trim())} />
            <SelectForm
              sx={{ width: "130px", ml: "auto" }}
              size="small"
              label="발주서 상태"
              handleChange={handleChangeStatus}
              value={status}
              options={statusOptions}
            />
            <CreateOrderButton />
            <RefreshIconButton onClick={() => refetch()} />
          </RowStack>
        </RowStack>

        {/* table */}
        <DataGrid
          className={"rdg-light padding0-cell"}
          columns={columns.map((column, i) => ({
            ...column,
            width: widthList[i],
          }))}
          rows={filteredRows}
          rowHeight={43.3}
          sortColumns={sortType ? [sortType] : []}
          onSortColumnsChange={handleChangeSortType}
          onRowsChange={handleUpdateCustomerInfo}
          onCellClick={handleCellClick}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
          }}
          onColumnResize={handleColumnResize}
          style={{
            height: "85%",
            // width: widthList.reduce((acc:any, curr:any) => acc + curr, 0),
            // maxWidth: "1000px",
          }}
          rowClass={handleChangeRowColor}
        />

        {/* bottom */}
        <RowStack
          gap={5}
          sx={{ p: 5, backgroundColor: "white", border: "1px solid #dfdfdf", borderTop: "0px solid" }}
        >
          <div></div>
          <Pagination
            siblingCount={4}
            count={Math.ceil((rows?.length || 1) / PAGE_SIZE)}
            size="large"
            onChange={(event, value: any) => {
              setCurrentPage(value);
            }}
          />
          <Typography variant="subtitle2" color="#666">
            {(currentPage - 1) * PAGE_SIZE + 1} ~ {Math.min(currentPage * PAGE_SIZE, rows?.length || 0)} / {rows?.length?.toLocaleString() || 0} 개
          </Typography>
        </RowStack>
      </CustomTableContainer>
    </>
  );
}
