import { Dialog, DialogTitle, DialogContent, Typography, Stack, TextField, Button, DialogActions, Box } from "@mui/material";
import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import { useBoxNameStore } from "../../../../store/boxNameStore";
import ProductInfo from "../ProductInfo";
import { searchProductResponseDto } from "../../../../service/total-order/types";
import { useUpdateFulfillments } from "../../../../service/total-order/totalOrderListHooks";
import { customToast } from "../../../../shared/lib/customToast";
import { CountTextStyle } from "../../style/style";
import { speakText } from "../../lib/speekText";
import { useUserInfoState } from "../../../../store/UserInfoStore";
import { useHistoryStore } from "../../../../store/totalOrderHIstory";
import RowStack from "../../../../shared/ui/RowStack";

interface BoxModalProps {
  open: boolean;
  onClose: () => void;
  info: searchProductResponseDto;
}

export const BoxModal: React.FC<BoxModalProps> = ({ open, onClose, info }) => {
  const { ToType, OrderAlias, OrderItemID, OrderID, BarCode, Quantity_Rest: limit } = info; //바코드로 찍은후 넘어온 제품 정보
  const countRef = useRef<HTMLInputElement>(null); //
  const boxNameRef = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [boxNumber, setBoxNumber] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { boxInfo, setBoxInfo, deleteBoxName } = useBoxNameStore((status) => status);
  const { mutate: updateFulfillments } = useUpdateFulfillments();

  const { setHistory } = useHistoryStore((v) => v);
  const { UserName } = useUserInfoState((v) => v);

  const type = useMemo(() => {
    return OrderAlias + "-" + boxNumber;
  }, [OrderAlias, boxNumber]);

  // 포커스를 할 때 모달이 열리는 시간을 생각해서 약간의 딜레이가 필요하다. 모달이 열리는 순간 포커스를 하면 포커스가 씹힐 수 있다.
  const dilayFocus = useCallback((ref: any) => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, []);

  // 바구니 번호 입력 후 엔터키 입력시 수량입력으로 포커싱 됨
  const handleKeyDwonEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmitBoxName();
    }
  };

  //시작 삭제를 등록하는 함수
  const handleCountKeyDwon = (e: any) => {
    if (e.key === "E") {
      handleSubmit();
    }

    if (e.key === "C") {
      setCount(0);
    }
  };

  // 바구니에 넣는 등록함수
  const handleSubmit = () => {
    if (count !== 0) {
      updateFulfillments({
        OrderID,
        OrderItemID,
        Quantity: count,
        ToType,
        ToObjectCode: type,
        BarCode,
      });

           //히스토리에 저장
           setHistory({
            OrderID: OrderID,
            OrderAlias: info.OrderAlias,
            BarCode: BarCode,
            CreatedAt: new Date().toLocaleString(),
            CreatedBy: UserName,
            Count: count,
            BasketID: type,
            ProductName: info.ProductName,
            Size: info.Size,
            Color: info.Color,
            Type: ToType,
          });

      onClose();
    } else {
      customToast("warn", "수량을 입력해 주세요.");
    }
  };

  //상자이름을 등록하는 함수
  const handleSubmitBoxName = () => {
    /**
     * 1. 바구니 이름이 유효한지 확인
     * 2. 유효하다면 전역변수에 등록하고 수량 입력으로 포커싱
     * 3. disabled 변경
     * 4. 유효하지 않다면 alert
     */

    if (boxNumber !== 0) {
      // 박스번호는 1번부터 시작함
      dilayFocus(countRef);
      setBoxInfo({ type, boxName: type });
      setDisabled(false);
    } else {
      customToast("warn", "0번 이외의 박스번호를 입력해주세요.");
      setBoxNumber(0);
    }

    // const isValid = true; // 초록바구니는 해당 로직이 필요하다.

    // if (isValid) {
    //   dilayFocus(countRef);
    //   setBoxInfo({ type, boxName });
    //   setDisabled(false);
    // } else {
    //   alert("사용할 수 없는 바구니 이름입니다.");
    // }
  };

  const handeChangeBoxName = () => {
    /**
     * 1. 등록된 바구니의 이름을 변경
     * 2. 버튼을 누르면 전역변수에 있는 바구니 이름 삭제
     * 3. boxName 초기화
     * 4. boxNameRef에 포커싱
     * 5. disabled를 false로 변경
     */

    deleteBoxName(type);
    setBoxNumber(0);
    setCount(0);
    setDisabled(true);
    dilayFocus(boxNameRef);
  };

  // 수량 입력시 숫자만 입력되도록 함
  const handleCountChange = (e: any) => {
    /**
     * 1. ENTER 입력시 => 등록버튼 클릭
     * 2. CLEAR 입력시 => 수량 삭제
     * 3. 숫자 입력시 => 수량 입력
     * 4. 다른 번호 입력시 => 이전 수량 유지
     *
     * 문제점 : 스캐너라 스케너라 할지라도 차례대로 입력되는건데 enter 가 그대로 유지될 수 있을까?
     */

    const value = e.target.value || 0;
    if (!isNaN(parseInt(value))) {
      if (parseInt(value) > limit) customToast("warn", "남은 수량보다 많은 수량을 입력하셨습니다.");
      const result = Math.max(0, Math.min(limit, parseInt(value)))
      speakText(result);
      setCount(result);
    }
  };

  useEffect(() => {
    const existBoxName = Object.keys(boxInfo).filter((v) => v.includes(OrderAlias))[0];

    if (open) {
      setCount(0);
      // 등록된 바구니 이름이 없다면
      if (!existBoxName) {
        /**
         * 1. 바구니 input 에 포커싱
         * 2. disabled를 true로 변경하여 수량 변경 못하게 막기
         * 3. boxName 초기화
         */
        dilayFocus(boxNameRef);
        setBoxNumber(0);
        setDisabled(true);
      } else {
        /**
         * 1. 수량 input 에 포커싱
         * 2. disabled를 false로 변경하여 수량변경 가능하게 하기
         * 3. boxName 전역변수에 있는 값으로 초기화
         */
        setBoxNumber(parseInt(existBoxName.split("-")[1]));
        setDisabled(false);
        dilayFocus(countRef);
      }
    }
  }, [open]);

  const handleChangeBoxNumber = (e: any) => {
    const value = e.target.value || 0;

    if (!isNaN(parseInt(value))) {
      const number = parseInt(value);
      setBoxNumber(number < 0 ? 0 : number);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"     sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "390px",
        },
      },
    }}
>
  <Box sx={{backgroundColor:"#A1784A", height:"30px"}}/>
    
      <DialogTitle id="alert-dialog-title" fontSize={"18px"} fontWeight="bold" sx={{color:"#A1784A"}}>
        상자 [{disabled || boxNumber === 0 ? "   " : `${OrderAlias}-${boxNumber}`}]
      </DialogTitle>
      <DialogContent>
        <ProductInfo data={info} />
        <Typography variant="subtitle1" color={!boxInfo[type] ? "error" : "primary"} sx={{ my: 5 }}>
          * {!boxInfo[type] ? "상자 번호를 입력해주세요. ex)1, 2, 3" : `남은 수량 : ${limit}개`}
        </Typography>
        <RowStack gap={1} sx={{ mb: 2 }}>
          <TextField value={OrderAlias} size="small" sx={{ width: "150px" }} disabled /> -
          <TextField
            inputRef={boxNameRef}
            id="boxNumber"
            size="small"
            sx={{ width: "100px" }}
            disabled={!disabled}
            value={boxNumber}
            onChange={handleChangeBoxNumber}
            onKeyDown={handleKeyDwonEnter}
          />
          {!disabled ? (
            <Button variant="outlined" size="large" onClick={handeChangeBoxName}>
              변경
            </Button>
          ) : (
            <Button variant="contained"size="large"  onClick={handleSubmitBoxName}>
              등록
            </Button>
          )}
        </RowStack>
        <Stack direction="row" gap={1}  justifyContent={"space-between"}>
          <TextField
          inputProps={{
            style:CountTextStyle
          }}
            disabled={disabled}
            inputRef={countRef}
            size="small"
            fullWidth
            label="수량"
            placeholder="수량을 입력해주세요."
            value={count}
            onKeyDown={handleCountKeyDwon}
            onChange={handleCountChange}
          />
          <Button
            disabled={disabled}
            variant="outlined"
            sx={{fontSize:"32px"}}
            onClick={() => {
              setCount((prev) => (prev + 1 > limit ? limit : prev + 1));
            }}
          >
            +
          </Button>
          <Button
            disabled={disabled}
            variant="outlined"
            sx={{fontSize:"32px"}}
            onClick={() => {
              setCount((prev) => (prev === 0 ? 0 : prev - 1));
            }}
          >
            -
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ mt: 2 }}>
        <Button disabled={disabled} onClick={handleSubmit} variant="contained">
          등록
        </Button>
        <Button onClick={onClose} variant="outlined">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};
