import { Button, IconButton, InputAdornment, Menu, Stack, TextField, Typography } from "@mui/material";
import DataGrid from "react-data-grid";
import { useState, useMemo, useEffect } from "react";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import RefreshIcon from "@mui/icons-material/Refresh";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useDeleteTargetFind, useGetTargetFindList, useUpdateTargetFindMemo } from "../../../service/target-find/targetFindHooks";
import { CreateTargetFindButton } from "./create-targe-find-modal/CreateTargetFindModal";
import RowStack from "../../../shared/ui/RowStack";
import { MultiRowCell } from "../../../shared/lib/multiRowCell";
import { getTargetFindItemDto } from "../../../service/target-find/types";
import { tableSortFunc } from "../../../shared/lib/tableSortFunction";
import ChecklistIcon from "@mui/icons-material/Checklist";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import { CenterCellRander } from "../../total-order/style/style";
interface SortType {
  columnKey: string;
  direction: "ASC" | "DESC";
}

const TargetFindTable = () => {
  const { data, refetch, isFetching } = useGetTargetFindList();
  const [filters, setFilters] = useState<string>("");
  const [sortType, setSortType] = useState<SortType>({ columnKey: "ReceiveDate", direction: "ASC" });
  const { mutateAsync: deleteTargetFind } = useDeleteTargetFind();
  const [selected, setSelectec] = useState<number[]>([]);
  const [rows, setRows] = useState<getTargetFindItemDto[]>(data || []);
  const { mutate: updateTargetFindMemo } = useUpdateTargetFindMemo();
  useEffect(() => {
    setRows(data || []);
  }, [data]);

  const handleDeleteFindOrder = async (row: any) => {
    const { FindItemID } = row;
    if (confirm("정말 삭제하시겠습니까?")) {
      await deleteTargetFind({ FindItemID });
    }
  };

  const handleDeleteAll = async () => {
    if (confirm("정말 삭제하시겠습니까?")) {
      for (let FindItemID of selected) {
        await deleteTargetFind({ FindItemID });
      }
    }
  };
  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateCustomerInfo = async (newData: any, oldData: any) => {
    // if (!oldData?.isCtrlZ) {
    //   const preOldData = { ...oldData, isCtrlZ: true };
    //   historyList.current.push({ newData, oldData: preOldData });
    //   console.log(historyList.current);
    //   console.log("일반 수정 실행");
    // } else {
    //   console.log("ctrl+z를 사용한 undo 실행");
    //   console.log(historyList.current);
    // }
    /**
     * newData : 새롭게 변경된 row[]
     * oldData : 변경되기 전 row
     */

    const idx = oldData.indexes[0];
    const key = oldData.column.key;
    const value = newData[idx][key];
    const FindItemID = newData[idx].FindItemID; //필수키
    updateTargetFindMemo({ FindItemID, [key]: value });
    setRows(newData);
  };

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType((prev) => ({ ...prev, direction: prev.direction === "ASC" ? "DESC" : "ASC" }));
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //필터를 적용함
  const filteredRows = useMemo(() => {
    if (!rows) return [];
    if (filters === "") return rows;
    return rows.filter((row) => {
      return Object.values(row).some((value) => {
        const str = !!value ? value.toString() : "";
        return str.includes(filters);
      });
    });
  }, [rows, filters]);

  useEffect(() => {
    setRows(data?.sort((a, b) => tableSortFunc(a, b, sortType)) || []);
  }, [sortType]);

  // Summary Row 생성 => 여기서 요약을 만들고 column 의 renderSummaryCell에서 뽑는다.
  const summaryRows = useMemo((): any[] => {
    return [
      {
        totalCount: rows?.length || 0,
        totalFindQuantity: rows?.reduce((acc, cur) => acc + cur.Quantity_Find, 0) || 0,
        totalTargetQuantity: rows?.reduce((acc, cur) => acc + cur.Quantity_Target, 0) || 0,
      },
    ];
  }, [rows]);

  //컬럼 테이블 생성
  const columns: any[] = [
    {
      key: "select",
      name: "선택",
      width: 65,
      renderHeaderCell: () => <CenterCellRander>선택</CenterCellRander>,
      renderCell: ({ row }: any) => {
        const FindItemID = row.FindItemID;
        return (
          <input
            type="checkbox"
            style={{ width: "100%", transform: "scale(1.4)" }}
            checked={selected.includes(FindItemID)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectec([...selected, FindItemID]);
              } else {
                setSelectec(selected.filter((idx) => idx !== FindItemID));
              }
            }}
          />
        );
      },
      renderSummaryCell: ({ row }: any) => {
        return "선택 : " + selected.length;
      },
    },
    {
      key: "BarCode",
      name: "바코드",
      minWidth: 200,
      renderSummaryCell: ({ row }: any) => {
        return "총 제품 종류 : " + row.totalCount + " 개";
      },
    },
    {
      key: "Detail01",
      name: "메모1",
      minWidth: 150,
      ...MultiRowCell(),
    },
    {
      key: "Detail02",
      name: "메모2",
      minWidth: 150,
      ...MultiRowCell(),
    },
    {
      key: "Quantity_Find",
      name: "찾은수량",
      width: 70,
      renderSummaryCell: ({ row }: any) => {
        return "총 : " + row.totalFindQuantity + " 개";
      },
    },
    {
      key: "Quantity_Target",
      name: "찾을수량",
      width: 70,
      renderSummaryCell: ({ row }: any) => {
        return "총 : " + row.totalTargetQuantity + " 개";
      },
    },
    {
      key: "CreatedAt",
      name: "생성일자",
      width: 150,
      renderCell: ({ row, column }: any) => {
        const value = row[column.key].split("T");
        return `${value[0]} ${value[1]}`;
      },
    },
    {
      key: "delete",
      name: "삭제",
      width: 70,
      renderCell: ({ row }: any) => {
        return (
          <IconButton onClick={() => handleDeleteFindOrder(row)}>
            <DeleteOutlineIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleRowColor = (row: any) => {
    if (row.Quantity_Find === row.Quantity_Target) {
      return "disabled-cell";
    } else {
      return "";
    }
  };

  return (
    <>
      <BackDropLoading open={isFetching} />
      <CustomTableContainer>
        <Stack>
          <RowStack gap={1} sx={{ backgroundColor: "white", p: 2, border: "1px solid #dddddd" }}>
            <Typography fontWeight="bold" fontSize="22px" sx={{ marginRight: "auto" }}>
              임의 찾기
            </Typography>
            {selected.length > 0 && (
              <Button color="error" size="large" sx={{ mr: 3 }} startIcon={<ChecklistIcon fontSize="small" />} onClick={handleDeleteAll}>
                일괄삭제 [{selected.length}]
              </Button>
            )}
            <SearchInputField onChange={(e) => setFilters(e.target.value)} value={filters} />
            <IconButton
              onClick={() => {
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>

            {/* 더보기 버튼 */}
            <IconButton
              id="basic-button"
              aria-controls={menuOpen ? "basic-menu" : undefined}
              aria-expanded={menuOpen ? "true" : undefined}
              onClick={handleOpenMenu}
            >
              <MoreVertOutlinedIcon />
            </IconButton>

            <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
              {/* <Divider />  */}
              <CreateTargetFindButton />
            </Menu>
          </RowStack>

          <DataGrid
            className="rdg-light"
            onSortColumnsChange={handleChangeSortType}
            style={{ height: "92vh", paddingBottom: "20px" }}
            rowHeight={autoRowHeight}
            onRowsChange={handleUpdateCustomerInfo}
            columns={columns}
            rowClass={handleRowColor}
            sortColumns={sortType ? [sortType] : []}
            rows={filteredRows || []}
            topSummaryRows={summaryRows} // 하단 요약 행 추가
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
          />
        </Stack>
      </CustomTableContainer>
    </>
  );
};
export default TargetFindTable;

const default_height = 40;
const add_height = 20;

export const autoRowHeight = (row: any, defaultRowHeight?: number) => {
  const memo0_Height = row.Detail01?.split("\n").length || 0;
  const memo1_Height = row.Detail02?.split("\n").length || 0;
  let rowCount = Math.max(memo1_Height, memo0_Height);
  rowCount = rowCount === 0 ? 0 : rowCount - 1;

  const height = defaultRowHeight || default_height;
  if (height !== default_height) {
    rowCount = rowCount - 1 < 0 ? 0 : rowCount - 1;
  }

  return rowCount * add_height + height;
};
