import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMemo, useState } from "react";
import { debounce } from "lodash";

interface SearchInputFieldProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  sx?: any;
}

/**
 * debounce를 사용하여 500ms 지연 후 onChange 호출
 * @param param0
 * @returns
 */
const SearchInputField: React.FC<SearchInputFieldProps> = ({ value, onChange, id, sx = {} }) => {
  const [inputValue, setInputValue] = useState(value);

  // lodash debounce를 사용하여 300ms 지연 후 onChange 호출
  const debouncedOnChange = useMemo(() => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChange(e), 500), [onChange]);

  // 입력값이 변경될 때 debouncedOnChange 호출
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debouncedOnChange(e);
  };

  return (
    <TextField
      size="small"
      id={id || ""}
      name={id || ""}
      InputProps={{
        startAdornment: <SearchIcon sx={{ mr: 2 }} />,
      }}
      value={inputValue}
      onChange={handleChange}
      placeholder="검색어를 입력하세요."
      sx={{ width: "300px", backgroundColor: "white", ...sx }}
    />
  );
};

export default SearchInputField;
