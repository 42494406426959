import OrderListTable from "../entities/order/ui/OrderListTable";
import React from "react";

const OrderList = () => {
  return (
    <>
      <OrderListTable />
    </>
  );
};

export default OrderList;
