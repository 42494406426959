import React from "react";
import { Button, Card, Stack } from "@mui/material";
import "react-data-grid/lib/styles.css";
import DateRangeButton from "../entities/customer-find-order/ui/DateRageButton";
import { useDateRangeStore, formatDate } from "../store/DateRangeStore";
import { UploadChangeBrandButton } from "../entities/product/ui/upload-change-brand-button/UploadChangeBrandButton";
import { useBrandCodeStore } from "../store/brandStore";
import { salesSummaryApi } from "../service/sales-summary/salesSummaryAxios";

const Test: React.FC = () => {
  const { endDate, startDate } = useDateRangeStore((v) => v.term);

  const BrandCode = useBrandCodeStore((s) => s.BrandCode);

  const handleClick = () => {
    salesSummaryApi
      .getTotalCustomerOrderSalesSummary({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      })
      .then((res) => {
        console.log(res);
      }),
      salesSummaryApi
        .getBrandCustomerOrderSalesSummary({
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          brand: "B001",
        })
        .then((res) => {
          console.log(res);
        });
  };

  return (
    <Card sx={{ mt: 10, height: "200px", width: "400xp", margin: "100px auto" }}>
      <Stack gap={5} alignItems={"center"}>
        <UploadChangeBrandButton sx={{ width: "200px", backgroundColor: "white", height: "46px", borderRadius: "0px" }} />
        <DateRangeButton />
        <Button variant="contained" onClick={handleClick} size="large">
          실행
        </Button>
      </Stack>
    </Card>
  );
};

export default Test;
