export const QUERY_KEY = {
  userList: "userList",
  userInfo: "userInfo",
  basket: "basket",
  basketList: "basketList",

  rack: "rack",
  rackList: "rackList",
  product: "product",
  productList: "productList",

  order: "order",
  OrderList: "orderList",
  items: "items",

  store: "store",
  storeList: "storeList",
  storeItem: "storeItem",

  totalOrderList: "totalOrderList",

  inventory: "inventory",
  incoming: "incoming",

  boxNameList: "boxNameList",

  orderFulfillments: "orderFulfillments",

  targetFind: "targetFind",

  chungdo: "chungdo",
  chungdoListAtRealTime: "chungdoListAtRealTime",

  customerFindOrder: "customerFindOrder",

  checkQuentityReadyAndOk: "checkQuentityReadyAndOk",
  checkArrivalQuantity: "checkArrivalQuantity",

  totalSalesSummary: "totalSalesSummary",
  brandSalesSummary: "brandSalesSummary",
  totalCustomerOrderSummary: "totalCustomerOrderSummary",
  brandCustomerOrderSummary: "brandCustomerOrderSummary",

  rocketFind:"rocketFind",
  barcodeAlert:"barcodeAlert",
};
