import "react-data-grid/lib/styles.css";
import { Button, Checkbox, Divider, InputAdornment, ListItemIcon, Menu, SelectChangeEvent, Stack, TextField, Tooltip } from "@mui/material";
import * as React from "react";
import { useRef, useState, useEffect, useMemo } from "react";
import DataGrid, { textEditor } from "react-data-grid";
import { useLocation } from "react-router-dom";
import { getOrderResponseDto, ItemResponse, updateOrderDto } from "../../../service/order/types";
import {
  useGetOrder,
  useUpdateOrderItem,
  useUpdateOrder,
  useUpdateSplitOrderItem,
  useHideOrderItems,
  useUpdateOrderItemBulk,
} from "../../../service/order/orderHooks";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, FormControl, Select } from "@mui/material";
import { CenterCellRander } from "../../total-order/style/style";
import BackDropLoading from "../../../shared/ui/loading/BackDropLoading";
import ShowInventoryButton from "../../../widget/show-inventory-modal/ui/ShowInventoryButton";
import ShowBoxNameListButton from "../../../widget/show-basket-list-modal/ui/ShowBoxNameListButton";
import ShowIncommingInventoryButton from "../../../widget/show-incomming-inventory-modal/ui/ShowIncommingInventoryButton";
import { customToast } from "../../../shared/lib/customToast";
import { MultiRowCell } from "../../../shared/lib/multiRowCell";

import AutoMultiCell from "../../../shared/ui/grid-cell/AutoMultiCell";
import { autoRowHeight } from "../../../shared/lib/autoRowHeight";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import ShowQuantityCoupangButton from "./show-quantity-coupang-modal/ShowQuantityCoupangButton";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ExportExcelButton from "./export-to-excel/ExportToExcelButton";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useUpdateProduct } from "../../../service/product/productHooks";
import SaveIcon from "@mui/icons-material/Save";
import { useLocalStorage } from "../../../service/localStorage/useLocalStorage";
import { SortType } from "../../../shared/type/SortType";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import { filterChildren } from "../lib/filterChildren";
import { setColumnWidths } from "../lib/setColumnWidth";
import { IndividualOrderMemoCell } from "./IndividualOrderMemoCell";
import { OrderCopyToClipboard } from "../lib/CopyToClicpboard";
import { ShipMentModal } from "./shipment-modal/ShipMentModal";
import { handleCopy, handlePaste } from "../../../shared/lib/dataGridTableCopyPaste";
import OrderAliasTitle from "./OrderAliasTitle";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import RefreshButton from "../../../shared/ui/buttom/RefreshButton";
import { getCurrentDateTime } from "../lib/getCurrentDateTime";
import { INDIVIDUAL_ORDER_TABLE_COLUMNS } from "../const/individualOrderTableColumns";
import { tableSortFunc } from "../../../shared/lib/tableSortFunction";
import IssueListButton from "./Issue-list-modal/IssueListButton";
import _ from "lodash";
import MoveMemoButton from "./move-memo-modal/MoveMemoButton";
import ChangeCenterButton from "./change-center-modal/ChangeCenterButton";
import SearchInputField from "../../../widget/form/ui/SearchInpuField";
import RowStack from "../../../shared/ui/RowStack";

const PRODUCT_TABLE_COLUMNS = ["Memo0", "MemoCustomerOrder"];
const QUANTITY_ERROR_EDIT_COLUMNS = ["Quantity_Ready", "Quantity_OK"];

export type OrderInfoType = Omit<getOrderResponseDto, "ItemResponse"> | undefined;
export type OrderItemType = ItemResponse;

const IndividualOrderTable: React.FC = () => {
  const { mutate: updatreOrderItemBulk } = useUpdateOrderItemBulk();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  //현재 페이지의 url을 가져옴
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get("keyword") || "";
  const OrderID = parseInt(location.pathname.split("/")[2]) || 0;
  const {
    data,
    refetch,
    isLoading,
    // isFetching,
  } = useGetOrder({ OrderID }, isEdit); // 데이터 불러오기 함수
  const [orderInfo, setOrderInfo] = useState<OrderInfoType>(data || undefined);
  const [orderItems, setOrderItems] = useState<OrderItemType[] | ItemResponse[]>(data?.items || []);

  useEffect(() => {
    if (data && data.items) {
      setOrderInfo(data);
      setStatus(data.Status);

      //예전 데이터 기준으로 reducer 돌기

      //n번
      const keyValueMap = new Map<number, OrderItemType>();
      const usedKeySet = new Set<number>();
      data.items.forEach((item) => {
        keyValueMap.set(item.OrderItemID, item); // 조회를 빠르게 하기 위해 map에 넣어둠
      });

      // n번
      //값 업데이트 + 삭제하기
      const newOrderItems: OrderItemType[] = orderItems.reduce((acc: any[], item) => {
        const id = item.OrderItemID;
        if (keyValueMap.has(id)) {
          usedKeySet.add(id);
          acc.push(keyValueMap.get(id));
        }
        return acc;
      }, []);

      // n번 새로운 값 추가하기 (추가된 값이 있을 경우)
      data.items.forEach((item) => {
        if (!usedKeySet.has(item.OrderItemID)) {
          newOrderItems.push(item);
        }
      });
      setOrderItems(newOrderItems);
    }
  }, [data]);

  const type = useMemo(() => {
    if (orderInfo) {
      const { IsFlex, OrderAlias } = orderInfo;
      return IsFlex ? (OrderAlias.includes("사전확정") ? "pre" : "flex") : "normal";
    } else {
      return "normal";
    }
  }, [orderInfo]);

  //로컬스토리지에서 컬럼값을 가져옴
  const [widthList, setWidthList] = useLocalStorage(
    `ATTERIC_INDIVIDUAL_ORDER_TABLE_COLUMN_${type.toUpperCase()}`,
    INDIVIDUAL_ORDER_TABLE_COLUMNS[type]
  );

  const { mutate: updateOrder } = useUpdateOrder(); // 발주서 데이터 업데이트 함수
  const [status, setStatus] = useState<string>("준비"); // 상태 함수 '준비' or '완료'
  const selectedRowId = useRef<number>(); //선택된 행의 인덱스
  const { mutate: updateOrderItem } = useUpdateOrderItem(); // 발주서 항목 업데이트
  const { mutate: hideSajeon } = useHideOrderItems((data) => {
    // 사전확정 항목 숨기기 => 보존처리
    setOrderItems((prev) => {
      return prev.filter((v) => data.includes(v.OrderItemID));
    });
  });

  //메모업데이트 함수
  const { mutate: updateProduct } = useUpdateProduct();
  const [filters, setFilters] = useState<string>(""); // 필터링 값
  const [sortType, setSortType] = useState<SortType>({ columnKey: "", direction: "ASC" }); // 정렬 기준
  const { mutate: updateSplitOrderItem } = useUpdateSplitOrderItem(); // 발주서 나누기
  const [selected, setSelected] = useState<number[]>([]); // 선택된 항목

  useEffect(() => {
    setSelected([]);
    if (keyword) {
      setFilters(keyword);
    }
  }, []);

  //발주서 파일 이름 생성 시, 시간 생성하는 함수

  //발주서 넘버 생성
  const totalOrderNumber: string[] = useMemo(() => {
    const result = new Set<string>();
    data?.items.forEach((item) => {
      result.add(item.IndividualOrderNumber);
    });
    return Array.from(result);
  }, [data]);

  //정렬할 데이터의 상태를 받아오는 함수
  const handleChangeSortType = (data: any) => {
    setIsEdit(false);
    const sortColumn = data as SortType[];
    // 이미 가지고 있는 정렬 컬럼이면 방향을 바꾸고 아니면 새로운 컬럼으로 정렬
    if (!sortColumn[0]) {
      setSortType((prev) => ({ ...prev, direction: prev.direction === "ASC" ? "DESC" : "ASC" }));
    } else if (sortType.columnKey === sortColumn[0].columnKey) {
      setSortType({
        columnKey: sortType.columnKey,
        direction: sortType.direction === "ASC" ? "DESC" : "ASC",
      });
    } else {
      setSortType(sortColumn[0]);
    }
  };

  //필터를 적용함
  const filteredRows = useMemo(() => {
    return orderItems.filter((data) => {
      return Object.values(data).some((value) => {
        return value?.toString().includes(filters);
      });
    });
  }, [data, orderItems, filters]);

  //정렬함수 동작
  useEffect(() => {
    setOrderItems((prev) => [...prev.sort((a, b) => tableSortFunc(a, b, sortType))]);
  }, [sortType]);

  //상태 변경 함수 준비or 완료
  const handleStatusChange = (event: SelectChangeEvent) => {
    const newStatus = event.target.value as string;

    // 오류수량이 하나라도 0 이면 이후 로직은 실행되지 않음
    if (!!orderInfo) {
      const isComplete = orderItems.every((v) => v.Quantity_Error === 0);
      if (isComplete || newStatus === "R") {
        // 완료 되었을 경우
        // dto 생성
        const data: updateOrderDto = {
          OrderID: orderInfo.OrderID,
          Status: newStatus,
          OrderAlias: orderInfo.OrderAlias,
        };
        updateOrder(data);
        setStatus(event.target.value as string);
      } else {
        customToast("warn", "완료되지 않은 발주서입니다.");
      }
    } else {
      customToast("error", "존재하지 않는 발주서입니다.");
    }
  };

  // 객체를 표시하기 위한 React 요소 생성 함수 (발주번호 column 값)
  const renderString = (arr: string[] | undefined) => {
    return (
      <AutoMultiCell>
        [발주번호]
        <br />
        <br />
        {arr?.join(", ")}
      </AutoMultiCell>
    );
  };

  //컬럼 값 저장 => localstorage에 저장하는거임
  const handleColumnResize = React.useCallback(
    _.debounce((index, width) => {
      setWidthList((prev: number[]) => {
        const newWidthList = [...prev];
        newWidthList[index] = width;
        return newWidthList;
      });
    }, 100),
    []
  );

  //입고일 발주일 값 생성
  const { ReceiveDate, OrderDate } = useMemo(() => {
    const receiveDate = orderInfo?.ReceiveDate?.split("-") || [];
    const OrderDate = orderInfo?.OrderDate?.split("-") || [];

    const result = {
      ReceiveDate: receiveDate.length === 3 ? `${receiveDate[0]} ${receiveDate[1]}-${receiveDate[2]}` : "",
      OrderDate: OrderDate.length === 3 ? `${OrderDate[0]} ${OrderDate[1]}-${OrderDate[2]}` : "",
    };

    return result;
  }, [orderInfo]);

  //모든 값 선택
  const handleSelectAll = () => {
    setSelected(orderItems.filter((v) => v.Quantity_Error !== 0 && v.IBCNumber !== "").map((item) => item.OrderItemID) || []);
  };

  //모든 값 선택
  const handleSelectAllStoragePreConfirm = () => {
    setSelected(orderItems.filter((v) => v.Quantity_Error === 0 && v.BoxNumberList.length === 0).map((item) => item.OrderItemID) || []);
  };

  const columns: any[] = [
    {
      key: "발주서 이름",
      name: <OrderAliasTitle OrderID={orderInfo?.OrderID || 0} />,
      headerCellClass: "blue-header1",
      children: [
        {
          key: "입고일",
          name: "입고일",
          headerCellClass: "blue-header2" + " multi-line-header",
          renderHeaderCell: () => <AutoMultiCell>입고 일</AutoMultiCell>,
          children: [
            {
              key: "발주일",

              name: "발주일",
              headerCellClass: "blue-header2" + " multi-line-header",
              renderHeaderCell: () => <AutoMultiCell>발주 일</AutoMultiCell>,
              children: [
                {
                  key: "센터",
                  name: "센터",
                  headerCellClass: "blue-header2",
                  children: [
                    {
                      key: "rowIdx",
                      name: "연번",
                      headerCellClass: "blue-header2",
                      renderCell: (p: any) => {
                        return <CenterCellRander> {p.row.rowIdx} </CenterCellRander>;
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          key: "입고일V",
          name: orderInfo?.ReceiveDate ? <AutoMultiCell>{ReceiveDate}</AutoMultiCell> : "",
          headerCellClass: "blue-header3" + " multi-line-header",
          children: [
            {
              key: "발주일V",
              name: orderInfo?.OrderDate ? <AutoMultiCell>{OrderDate}</AutoMultiCell> : "",
              headerCellClass: "blue-header3" + " multi-line-header",
              children: [
                {
                  key: "센터V",
                  name: orderInfo?.Center ? <AutoMultiCell>{orderInfo.Center}</AutoMultiCell> : "",
                  headerCellClass: "blue-header3",
                  children: [
                    {
                      key: "IndividualOrderNumber",
                      name: "발주번호",
                      renderHeaderCell: () => <AutoMultiCell>발주 번호</AutoMultiCell>,
                      headerCellClass: "blue-header2",
                      renderCell: ({ row, column }: any) => {
                        const { key } = column;
                        return <AutoMultiCell>{row[key]}</AutoMultiCell>;
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          key: "발주수량K",
          name: <AutoMultiCell>발주 수량</AutoMultiCell>,
          headerCellClass: "blue-header2 multi-line-header",
          renderHeaderCell: () => <AutoMultiCell>발주 수량</AutoMultiCell>,
          children: [
            {
              key: "확정수량K",
              name: <AutoMultiCell>확정 수량</AutoMultiCell>,
              headerCellClass: "blue-header2",
              children: [
                {
                  key: "준비수량K",
                  name: <AutoMultiCell>준비 수량</AutoMultiCell>,
                  headerCellClass: "blue-header2",
                  children: [
                    {
                      key: "SKU_ID",
                      name: "상품번호",
                      headerCellClass: "blue-header2",
                      renderHeaderCell: () => <AutoMultiCell>상품 번호</AutoMultiCell>,
                      renderCell: ({ row, column }: any) => {
                        const { key } = column;
                        return <AutoMultiCell>{row[key]}</AutoMultiCell>;
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          key: "발주수량V",
          name: orderInfo?.sumQuantity ? orderInfo.sumQuantity + "" : 0,
          headerCellClass: "blue-header3",
          children: [
            {
              key: "확정수량V",
              name: orderInfo?.sumQuantityOK ? orderInfo.sumQuantityOK + "" : 0,
              headerCellClass: "blue-header3",
              children: [
                {
                  key: "준비수량V",
                  name: orderInfo?.sumQuantityReady ? orderInfo.sumQuantityReady + "" : 0,
                  headerCellClass: "blue-header3",
                  children: [
                    {
                      key: "BarCode",
                      name: "상품바코드",
                      renderHeaderCell: () => <AutoMultiCell>상품 바코드</AutoMultiCell>,
                      headerCellClass: "blue-header2" + " multi-line-header",
                      renderCell: ({ row }: any) => {
                        return <AutoMultiCell>{row.BarCode}</AutoMultiCell>;
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          key: "발주번호",
          name: renderString(totalOrderNumber),
          headerCellClass: "order-number",
          children: [
            {
              key: "ProductName",
              name: "상품명",
              headerCellClass: "blue-header2",
              renderCell: ({ row }: any) => {
                return <AutoMultiCell>{row.ProductName}</AutoMultiCell>;
              },
            },
            {
              key: "Size",
              name: "사이즈",
              headerCellClass: "blue-header2",
              renderCell: (p: any) => {
                return <CenterCellRander>{p.row.Size}</CenterCellRander>;
              },
            },
            {
              key: "Color",
              name: "컬러",
              headerCellClass: "blue-header2",
              renderCell: (p: any) => {
                return <AutoMultiCell>{p.row.Color}</AutoMultiCell>;
              },
            },

            {
              key: "Status",
              name: "발주상태",
              renderHeaderCell: () => <AutoMultiCell>발주 상태</AutoMultiCell>,
              headerCellClass: "blue-header2" + " multi-line-header",
              renderCell: (p: any) => {
                if (p.row.Status === "R") {
                  return <CenterCellRander>준비</CenterCellRander>;
                } else {
                  return <CenterCellRander>완료</CenterCellRander>;
                }
              },
            },
            {
              key: "사전확정",
              name: "사전확정",
              renderHeaderCell: () => <AutoMultiCell>사전 확정</AutoMultiCell>,
              headerCellClass: "blue-header2" + " multi-line-header",
              renderCell: ({ row }: any) => {
                const { OrderItemID, IBCNumber, Quantity_Error } = row;
                return (
                  <Checkbox
                    checked={selected.includes(OrderItemID)}
                    onChange={() => {
                      if (Quantity_Error !== 0 && !!IBCNumber) {
                        setSelected(selected.includes(OrderItemID) ? selected.filter((id) => id !== OrderItemID) : [...selected, OrderItemID]);
                      } else {
                        customToast(
                          "warn",
                          <p>
                            [조건]
                            <br />
                            오류수량이 0이 아닌것
                            <br />
                            IBC 하차번호가 있는것
                          </p>
                        );
                      }
                    }}
                  />
                );
              },
            },

            {
              key: "Memo0",
              name: "자동상품 메모",
              headerCellClass: "blue-header2",
              ...MultiRowCell(),
            },
            {
              key: "Memo1",
              name: "발주담당자 메모",
              headerCellClass: "blue-header2",
              ...MultiRowCell(),
            },
            {
              key: "Memo2",
              name: "청도주문 메모",
              headerCellClass: "blue-header2",
              ...MultiRowCell(),
            },
            {
              key: "Memo3",
              name: "기타메모",
              headerCellClass: "blue-header2",
              ...MultiRowCell(),
            },
          ],
        },
        {
          key: "기타",
          name: IndividualOrderMemoCell(orderInfo?.OrderID || 0),
          headerCellClass: "text-header-cell",
          children: [
            {
              key: "TotalCount",
              name: "재고현황",
              renderHeaderCell: () => <AutoMultiCell>재고 현황</AutoMultiCell>,
              headerCellClass: "pink-header" + " multi-line-header",
              cellClass: "pink-header",
              renderCell: ({ row }: any) => {
                const { TotalCount, BarCode } = row;
                return <ShowInventoryButton BarCode={BarCode} TotalCount={TotalCount} />;
              },
            },
            {
              key: "IncomingCount",
              name: "입고현황",
              cellClass: "yellow-header",
              renderHeaderCell: () => <AutoMultiCell>입고 현황</AutoMultiCell>,
              headerCellClass: "yellow-header" + " multi-line-header",
              renderCell: ({ row }: any) => {
                const { IncomingCount, BarCode } = row;
                return <ShowIncommingInventoryButton BarCode={BarCode} IncommingCount={IncomingCount} />;
              },
            },
            {
              key: "Quantity",
              name: "발주수량",
              renderHeaderCell: () => <AutoMultiCell>발주 수량</AutoMultiCell>,
              headerCellClass: "quantity-header" + " multi-line-header",
              renderCell: (p: any) => {
                return <CenterCellRander>{p.row.Quantity}</CenterCellRander>;
              },
            },
            {
              key: "Quantity_OK",
              name: "확정수량",
              editable: true,
              renderHeaderCell: () => <AutoMultiCell>확정 수량</AutoMultiCell>,
              headerCellClass: "quantity-header" + " multi-line-header",
              renderEditCell: textEditor,
              renderCell: (p: any) => {
                return <CenterCellRander>{p.row.Quantity_OK}</CenterCellRander>;
              },
            },
            {
              key: "Lock",
              name: "보존",
              headerCellClass: "quantity-header",
              renderCell: ({ row }: any) => {
                const { OrderItemID, Quantity_Error, BoxNumberList } = row;
                return (
                  <CenterCellRander>
                    <Checkbox
                      checked={selected.includes(OrderItemID)}
                      onChange={() => {
                        if (Quantity_Error === 0 && BoxNumberList.length === 0) {
                          setSelected(selected.includes(OrderItemID) ? selected.filter((id) => id !== OrderItemID) : [...selected, OrderItemID]);
                        } else {
                          customToast(
                            "warn",
                            <p>
                              오류수량이 0이고 토트번호가
                              <br />
                              없어야 보관이 가능합니다.
                            </p>
                          );
                        }
                      }}
                    />
                  </CenterCellRander>
                );
              },
            },

            {
              key: "BoxNumberList",
              name: orderInfo?.IsFlex == true ? "토트번호" : "박스번호",
              headerCellClass: "blue-header2",
              cellClass: "memo-cell",
              renderCell: ({ row }: any) => {
                const { Quantity_OK, BoxNumberList } = row;
                return (
                  <pre style={{ margin: "0px", padding: "0px 10px", position: "relative", lineHeight: "1.1rem" }}>
                    {Quantity_OK !== 0
                      ? BoxNumberList
                        ? BoxNumberList.filter((v: any) => v.Quantity !== 0)
                            .map((box: any, key: any) => `${box.ToObjectCode}(${box.Quantity})`)
                            .join("\n")
                        : ""
                      : "*"}
                    {row.isSajeon && (
                      <span
                        style={{
                          position: "absolute",
                          fontWeight: "bold",
                          color: "rgba(200,0,0,0.2)",
                          top: "10px",
                          left: "10px",
                          transform: "rotate(-20deg)",
                        }}
                      >
                        사전확정
                      </span>
                    )}
                  </pre>
                );
              },
            },

            {
              key: "Quantity_Ready",
              name: "준비수량",
              headerCellClass: "quantity-header" + " multi-line-header",
              renderHeaderCell: () => <AutoMultiCell>준비 수량</AutoMultiCell>,
              renderCell: ({ row }: any) => {
                const { Quantity_Ready, OrderItemID, BarCode } = row;
                return (
                  <ShowBoxNameListButton
                    row={row}
                    OrderID={OrderID}
                    Quantity_Ready={Quantity_Ready}
                    Center={orderInfo?.Center || ""}
                    OrderItemID={OrderItemID}
                    BarCode={BarCode}
                  />
                );
              },
            },
            {
              key: "Quantity_coupang",
              name: "플완",
              headerCellClass: "skyblue-header",
              cellClass: "skyblue-header",
              renderCell: ({ row }: any) => {
                return <ShowQuantityCoupangButton row={row} />;
              },
            },
            {
              key: "Quantity_Error",
              name: "오류수량",
              renderHeaderCell: () => <AutoMultiCell>오류 수량</AutoMultiCell>,
              headerCellClass: "quantity-header" + " multi-line-header",
              renderCell: ({ row }: any) => {
                return <CenterCellRander>{row.Quantity_Error}</CenterCellRander>;
              },
            },

            {
              key: "IBCNumber",
              name: "IBC하차번호",
              headerCellClass: "blue-header2" + " multi-line-header",
              renderEditCell: textEditor,
              renderHeaderCell: () => <AutoMultiCell>IBC 하차번호</AutoMultiCell>,
              renderCell: ({ row }: any) => {
                return <AutoMultiCell>{row.IBCNumber}</AutoMultiCell>;
              },
            },
            {
              key: "RocketMargin",
              name: "로켓마진",
              headerCellClass: "blue-header2" + " multi-line-header",
              // renderEditCell: textEditor,
              renderHeaderCell: () => <AutoMultiCell>로켓 마진</AutoMultiCell>,
              renderCell: ({ row }: any) => {
                return <AutoMultiCell>{row.RocketMargin?.toLocaleString() || "-"}</AutoMultiCell>;
              },
            },
          ],
        },
      ],
    },
  ];

  //보존처리하기
  const handlehideOrderItem = () => {
    hideSajeon(selected);
  };

  //마우스로 클릭한 행 값을 저장함
  const handleSelectedRow = (cell: any) => {
    selectedRowId.current = cell.rowIdx;
  };

  //수정된 값을 서버로 보내어 업데이트함
  const handleUpdateRowsInfo = async (newData: any, oldData: any) => {
    /**
     * newData : 새롭게 변경된 row[]
     * oldData : 변경되기 전 row
     */
    const idx = oldData.indexes[0];
    const key = oldData.column.key;
    const value = newData[idx][key];
    const BarCode = newData[idx].BarCode; //필수키
    const OrderItemID = newData[idx].OrderItemID; //필수키

    if (PRODUCT_TABLE_COLUMNS.includes(key)) {
      // 자동상품 메모 일괄 변경
      updateProduct({ BarCode, [key]: value });
      setOrderItems((oldData: OrderItemType[]) => {
        return oldData.map((item) => {
          if (item.BarCode === BarCode) {
            return { ...item, [key]: value };
          }
          return item;
        });
      });
    } else {
      updateOrderItem({ OrderItemID, [key]: value });
      if (QUANTITY_ERROR_EDIT_COLUMNS.includes(key)) {
        setOrderItems(() =>
          newData.map((item: OrderItemType) =>
            OrderItemID === item.OrderItemID ? { ...item, Quantity_Error: item.Quantity_OK - item.Quantity_Ready } : item
          )
        );
      } else {
        setOrderItems(newData); // 그냥 넣으면 됨
      }
    }

    setIsEdit(false);
  };

  //선택된 항목 사전확정
  const handleSplitOrderItem = () => {
    const data = orderItems
      .filter((item: ItemResponse) => selected.includes(item.OrderItemID))
      .map((item: ItemResponse) => ({
        OrderItemID: item.OrderItemID,
        Quantity_Split: item.Quantity_Error,
      }));

    if (data.length !== 0) {
      if (confirm("선택된 항목을 사전확정 하시겠습니까?")) {
        updateSplitOrderItem(data);
        setSelected([]);
      }
    }
  };

  //행의 색깔을 변경하는 함수
  const handleRowColor = (row: ItemResponse) => {
    const { SupplyPrice, QuotedPrice, Quantity_Error, RocketMargin, Memo0 } = row;

    // 견적가랑 공급가격이 20원 이상 차이나는 것 빨간색으로 표시
    if ((SupplyPrice || 0) !== 0 && Math.abs((SupplyPrice || 0) - (QuotedPrice || 0)) > 20) {
      return "alert-cell";
    }

    //로켓마진이 없는것 노란색으로 표시
    if (!RocketMargin) {
      return "empty-margin-cell";
    }

    //로켓마진이 1000원 미만인것을 파란색으로 표시
    if (RocketMargin < 1000 && !Memo0?.includes("마진 1000원 미만 확인 완료")) {
      return "row-margin-price";
    }

    // 준비수량과 확정수량이 같으면 색깔을 변경함
    if (Quantity_Error === 0) {
      return "disabled-cell";
    }

    return "";
  };

  //SupplyPrice와 QuotedPrice가 20원 이상 차이나는 값들을 찾아냄
  const { mismatchedValues, lowRocketMargin, emptyRocketMargin, totalCount } = useMemo(() => {
    //위의 함수를 reduce로 다시구현

    const mismatchedValues: any[] = [];
    const lowRocketMargin: any[] = [];
    const emptyRocketMargin: any[] = [];
    let totalCount = 0;

    orderItems.forEach((item) => {
      const { SupplyPrice, QuotedPrice, RocketMargin, Memo0 } = item;

      if ((SupplyPrice || 0) !== 0 && Math.abs((SupplyPrice || 0) - (QuotedPrice || 0)) > 20) {
        mismatchedValues.push(item);
        totalCount++;
      } else if (!RocketMargin) {
        emptyRocketMargin.push(item);
        totalCount++;
      } else if (RocketMargin < 1000 && !Memo0?.includes("마진 1000원 미만 확인 완료")) {
        lowRocketMargin.push(item);
        totalCount++;
      }
    });

    return { mismatchedValues, lowRocketMargin, emptyRocketMargin, totalCount };
  }, [orderItems]);

  // // 한번에 여러줄 채우기
  // function handleFill({ columnKey, sourceRow, targetRow }: FillEvent<Row>) {
  //   const BarCode = targetRow.BarCode; //필수키
  //   const OrderItemID = targetRow.OrderItemID; //필수키

  //   PRODUCT_TABLE_COLUMNS.includes(columnKey)
  //     ? updateProduct({ BarCode, [columnKey]: sourceRow[columnKey] })
  //     : updateOrderItem({ OrderItemID, [columnKey]: sourceRow[columnKey] });

  //   return { ...targetRow, [columnKey]: sourceRow[columnKey as keyof Row] };
  // }

  // 사전확정을 위한 매뉴 열기
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // 보존을 위한 매뉴 열기
  const [bozon, setbozon] = useState<null | HTMLElement>(null);
  const bozonOpen = Boolean(bozon);
  const handleOpenBozon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setbozon(event.currentTarget);
  };
  const handleCloseBozon = () => {
    setbozon(null);
  };

  const handleQuantityOkToZero = () => {
    if (confirm("이 작업은 되돌릴 수 없습니다.\n확정수량을 0으로 처리하시겠습니까?")) {
      // console.log(orderItems.map((item) => ({ OrderItemID: item.OrderItemID, Quantity_OK: 0 })));
      updatreOrderItemBulk(orderItems.map((item) => ({ OrderItemID: item.OrderItemID, Quantity_OK: 0 })));

      setOrderItems((prev) => {
        return prev.map((item) => {
          return { ...item, Quantity_OK: 0 };
        });
      });
    }
  };

  return (
    <>
      <CustomTableContainer>
        <BackDropLoading open={isLoading} />
        <Stack>
          {/* 개별발주서 버튼 모음 */}
          {orderInfo && (
            <RowStack gap={2} sx={{ my: 3 }}>
              <SearchInputField value={filters} onChange={(e) => setFilters(e.target.value)} sx={{ mr: "auto" }} />
              {totalCount !== 0 && (
                <IssueListButton
                  mismatchedValues={mismatchedValues}
                  lowRocketMargin={lowRocketMargin}
                  emptyRocketMargin={emptyRocketMargin}
                  totalCount={totalCount}
                />
              )}
              <Button sx={{ backgroundColor: "white" }} variant="outlined" onClick={handleQuantityOkToZero}>
                확정수량 0장처리
              </Button>
              <MoveMemoButton orderInfo={orderInfo} orderItems={orderItems} />
              <RefreshButton onClick={() => refetch()} isFetching={false} />
              {type === "pre" && (
                <>
                  <Button onClick={handleOpenBozon} variant="outlined" sx={{ backgroundColor: "white" }}>
                    보존하기 [{selected.length}]
                  </Button>
                  <Menu anchorEl={bozon} open={bozonOpen} onClose={handleCloseBozon}>
                    <Tooltip title={<p>보존 항목을 모두 선택합니다.</p>} arrow placement="right">
                      <MenuItem onClick={handleSelectAllStoragePreConfirm}>
                        <ListItemIcon>
                          <CheckBoxOutlinedIcon />
                        </ListItemIcon>
                        전체 보존항목 선택
                      </MenuItem>
                    </Tooltip>
                    <Divider />
                    <MenuItem onClick={() => setSelected([])}>
                      <ListItemIcon>
                        <IndeterminateCheckBoxOutlinedIcon />
                      </ListItemIcon>
                      보존항목 대상 해제
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handlehideOrderItem}>
                      <ListItemIcon>
                        <SaveIcon />
                      </ListItemIcon>
                      선택된 항목 보존하기
                    </MenuItem>
                  </Menu>
                </>
              )}

              {type === "flex" && (
                <>
                  <Button onClick={handleOpenMenu} variant="outlined" sx={{ backgroundColor: "white" }}>
                    사전확정 [{selected.length}]
                  </Button>
                  <Menu anchorEl={menuAnchorEl} open={menuOpen} onClose={handleCloseMenu}>
                    <Tooltip
                      title={
                        <p>
                          [조건]
                          <br />
                          IBC 하차번호가 있고
                          <br />
                          오류수량이 0이상인 제품
                        </p>
                      }
                      arrow
                      placement="right"
                    >
                      <MenuItem onClick={handleSelectAll}>
                        <ListItemIcon>
                          <CheckBoxOutlinedIcon />
                        </ListItemIcon>
                        사전확정 대상 선택
                      </MenuItem>
                    </Tooltip>
                    <Divider />
                    <MenuItem onClick={() => setSelected([])}>
                      <ListItemIcon>
                        <IndeterminateCheckBoxOutlinedIcon />
                      </ListItemIcon>
                      사전확정 대상 해제
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleSplitOrderItem}>
                      <ListItemIcon>
                        <FactCheckOutlinedIcon />
                      </ListItemIcon>
                      선택된 항목 사전확정
                    </MenuItem>
                  </Menu>
                </>
              )}
              {/* 발주서 내용 추가  */}
              {/* {!!response && type === "normal" && <AdditionalRegistModal data={response} />} */}

              <Button
                onClick={() => OrderCopyToClipboard(orderItems)}
                variant="outlined"
                startIcon={<ContentPasteIcon />}
                sx={{ backgroundColor: "white" }}
              >
                클립보드
              </Button>
              {type !== "pre" && <ChangeCenterButton data={data} />}
              <ExportExcelButton data={orderItems} fileName={orderInfo?.OrderAlias + "_" + getCurrentDateTime() || "발주서"} />
              {type === "normal" && <ShipMentModal items={orderItems} OrderId={orderInfo?.OrderID} ShipMent={orderInfo?.ShipMent} />}

              {type !== "pre" && (
                <FormControl sx={{ minWidth: 110, height: 40, backgroundColor: "white" }} size="small">
                  <InputLabel id="demo-simple-select-label" sx={{ color: "#1976d2" }}>
                    발주상태
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{ color: "#1976d2" }}
                    value={status}
                    label="status"
                    onChange={handleStatusChange}
                  >
                    <MenuItem value={"E"}>완료</MenuItem>
                    <MenuItem value={"R"}>준비</MenuItem>
                  </Select>
                </FormControl>
              )}
            </RowStack>
          )}
        </Stack>
        <Stack gap={5}>
          <DataGrid
            className={"rdg-light"} //'rdg-dark'
            rowHeight={(row) => autoRowHeight(row, 53)}
            columns={setColumnWidths(filterChildren(columns, type), widthList)}
            rows={filteredRows}
            // onFill={handleFill}
            headerRowHeight={40}
            onSelectedCellChange={handleSelectedRow}
            onRowsChange={handleUpdateRowsInfo}
            // onCellKeyDown={handleCellKeyDown}
            onSortColumnsChange={handleChangeSortType}
            defaultColumnOptions={{
              sortable: true,
              resizable: true,
            }}
            style={{ height: "90vh", paddingBottom: "20px" }} // 원하는 높이로 변경, DataGrid 기본값 350px
            rowClass={handleRowColor}
            onColumnResize={handleColumnResize}
            onCopy={handleCopy}
            onPaste={handlePaste}
            onCellClick={(e) => {
              setIsEdit(true);
            }}
          />
        </Stack>
      </CustomTableContainer>
    </>
  );
};

export default IndividualOrderTable;
