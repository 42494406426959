import React, { useState, useEffect, useMemo } from "react";
import * as XLSX from "xlsx";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import { customToast } from "../../../../shared/lib/customToast";
import { customAxios } from "../../../../config/axios-config";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useCreateTargetFind } from "../../../../service/target-find/targetFindHooks";
import { createTargetFindItemDto } from "../../../../service/target-find/types";

const key = ["BarCode", "Quantity_Target", "Detail01", "Detail02"];

export const CreateTargetFindButton: React.FC = () => {
  const { mutate: addBarCodeItem } = useCreateTargetFind();
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<createTargetFindItemDto[]>([]);

  const isExcelData = useMemo(() => {
    return excelData.length > 0;
  }, [excelData]);

  useEffect(() => {
    setExcelData([]);
  }, [open]);

  //모달창 여닫기
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  // 엑셀 데이터 제출하기
  const handleExcelDataSubmit = () => {
    if (excelData.length !== 0) {
      // console.log(excelData);
      addBarCodeItem(excelData);
    } else {
      customToast("warn", "엑셀파일을 등록해주세요.");
    }
  };

  //등록 버튼 누르기
  const handleSubmit = () => {
    handleExcelDataSubmit();
    handleChangeOpen();
  };

  //엑셀 업로드 하기
  const handleFileUpload = (files: FileList | null) => {
    const file = files?.[0];
    if (file) {
      customToast("info", "엑셀 파일이 업로드되었습니다.");
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const newMData = rows
          .slice(1)
          .filter((v) => v.length !== 0)
          .map((row: string[]) => {
            const obj: any = {};
            key.forEach((key, index) => {
              let value: any = row[index] || "";
              if (typeof row[index] === "number") {
                value = row[index];
              }
              obj[key] = value;
            });
            return obj;
          });

        setExcelData(newMData);
      };
      reader.readAsArrayBuffer(file);
    } else {
      customToast("warn", "엑셀을 선택해 주세요.");
    }
  };

  // 엑셀양식 다운로드
  const handleDownload = async () => {
    try {
      const response = await customAxios.get(`/find_items/getXlsxFormat/`, {
        responseType: "blob",
      });

      // Blob 데이터를 사용해서 URL 생성
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "엑셀 양식.xlsx"); // 다운로드할 파일 이름
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };

  return (
    <React.Fragment>
      <MenuItem onClick={handleChangeOpen}>
        <ListItemIcon>
          <BackupTableIcon />
        </ListItemIcon>
        엑셀 업로드
      </MenuItem>
      <Dialog open={open} maxWidth={"xs"} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle>엑셀파일 등록</DialogTitle>
        <DialogContent sx={{ pb: 0, pl: 8 }}>
          <Typography color={isExcelData ? "primary" : ""} sx={{ mb: 1 }}>
            {isExcelData ? "파일이 업로드 되었습니다!" : "파일을 업로드 해주세요."}
          </Typography>
          <Box width={300} sx={{ mb: 2 }} margin="auto">
            <Stack gap={2}>
              <label htmlFor="file-upload">
                <Button component="span" sx={{ height: "80px" }} variant="contained" tabIndex={-1} fullWidth startIcon={<UploadFileOutlinedIcon />}>
                  파일 업로드
                </Button>
              </label>
              <input
                style={{ display: "none" }}
                id="file-upload"
                type="file"
                accept=".xlsx, .xls .csv"
                onChange={(e) => handleFileUpload(e.target.files)}
              />
              <Button
                sx={{ height: "80px" }}
                fullWidth
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                onClick={handleDownload}
                startIcon={<DownloadOutlinedIcon />}
              >
                양식 다운로드
              </Button>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" disabled={!isExcelData}>
            등록
          </Button>
          <Button onClick={handleChangeOpen} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
