import * as XLSX from "xlsx";
import React from "react";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useExcelData } from "../../../../service/order/orderContext";

export const map = [
  "orderNumber",
  "center",
  "type",
  "state",
  "productNumber",
  "productBarCode",
  "productName",
  "orderQuantity",
  "confirmQuantity",
  "expirationDate",
  "manufactureDate",
  "year",
  "reason",
  "manager",
  "managerNumber",
  "address",
  "purchasePrice",
  "supplyPrice",
  "vat",
  "total",
  "receiveDate",
  "orderDate",
];

const UploadOrderFileButton: React.FC = () => {
  const { setExcelData } = useExcelData();

  const handleFileUpload = (files: FileList | null) => {
    const file = files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (rows) {
          const mappedData = rows.slice(1).map((row: any[]) => {
            const obj: any = {};
            map.forEach((key, index) => {
              obj[key] = row[index];
            });
            return obj;
          });

          const groupedData = mappedData.reduce((acc, item) => {
            if (!acc[item.center]) acc[item.center] = {}; // 센터별 
            if (!acc[item.center][item.receiveDate]) acc[item.center][item.receiveDate] = []; //날짜별 
            acc[item.center][item.receiveDate].push(item)
            return acc;
          }, {} as Record<string, Record<string, any[]>>);
          setExcelData(groupedData);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Box>
      <Button component="label" role={undefined} variant="contained" tabIndex={-1} size="large" startIcon={<CloudUploadIcon />}>
        발주서 업로드
        <input type="file" accept=".xlsx, .xls, .csv" onChange={(e) => handleFileUpload(e.target.files)} hidden />
      </Button>
    </Box>
  );
};

export default UploadOrderFileButton;
