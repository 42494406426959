import { useState } from "react";

// 커스텀 훅: localStorage와 상태 관리
export function useLocalStorage(key: string, initialValue: number[]) {
  const KEY: string = "TABLE_WIDTH";
  const [widthList, setWidthListin] = useState(JSON.parse(window.localStorage.getItem(KEY) || "{}")[key] || initialValue);
  const setWidthList = (value: any) => {
    try {
      const updatedTableWidth = value instanceof Function ? value(widthList) : value;
      setWidthListin(updatedTableWidth);
      const tableWidth = JSON.parse(window.localStorage.getItem(KEY) || "{}");
      tableWidth[key] = updatedTableWidth;
      window.localStorage.setItem(KEY, JSON.stringify(tableWidth));
    } catch (error) {
      console.error(error);
    }
  };

  return [widthList, setWidthList];
}
