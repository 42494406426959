import * as React from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridToolbar,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridRowSelectionModel,
} from "@mui/x-data-grid";

import BarcodeButton from "../../../widget/show-bar-code-modal/ui/BarCodeButton";
import QrCodeButton from "../../../widget/show-qr-code-modal/ui/QrCodeButton";
import { CreateBasketButton } from "./create-basket-modal/CreateBasketButton";
import { useDeleteBasket, useGetBasketList, useUpdateBasket } from "../../../service/basket/BasketHooks";
import CustomPagination from "../../../widget/custom-pagination/ui/CustomPagination";
import { dataGridStyle, otherToolbar, initialState } from "../../../shared/style/mui-table-style/MuiTableStyle";
import { getTogglableColumns } from "../../../shared/lib/columnMenuPanel";
import { deleteBasketDto } from "../../../service/basket/types";
import { useUserInfoState } from "../../../store/UserInfoStore";
import TitleAndMenu from "../../../widget/title-and-menu/TitleAndMenu";
import { CustomTableContainer } from "../../../widget/custom-table-container/ui/CustomTableContainer";
import ProductListInBasketButton from "../../../widget/show-productlist-modal/ProductListInBasketButton";

export default function BasketTable() {
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({}); // 현재 변경중인 값
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const { mutate: deleteBasket } = useDeleteBasket();
  const { mutate: updateBasket } = useUpdateBasket();
  const { data: rows, refetch, isFetching } = useGetBasketList();
  const { IsStaff } = useUserInfoState((s) => s);

  //편집 중지
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  //삭제 클릭
  const handleDeleteClick = (data: deleteBasketDto) => () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteBasket(data);
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    const { BasketID, CoupangBaskID } = newRow;
    updateBasket({ BasketID, CoupangBaskID, IsCoupang: CoupangBaskID ? true : false });
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "BasketID",
      headerName: "코드",
      type: "string",
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) => {
        const BasketID = params.row.BasketID;
        return <span>{BasketID}</span>;
      },
    },

    {
      field: "LastUpdated",
      headerName: "수정시간",
      flex: 1,
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      type: "dateTime",
    },
    {
      field: "barCode",
      headerName: "바코드",
      type: "actions",
      minWidth: 80,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <BarcodeButton key={params.row.BasketID} value={params.row.BasketID} />;
      },
    },
    {
      field: "qrCode",
      headerName: "qr코드",
      type: "actions",
      minWidth: 80,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <QrCodeButton key={params.row.BasketID} value={params.row.BasketID} />;
      },
    },

    {
      field: "products",
      headerName: "물품목록 ",
      type: "actions",
      minWidth: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const code = params.row.BasketID;
        return <ProductListInBasketButton code={code} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      align: "center",
      flex: 1,
      headerAlign: "center",
      headerName: "삭제",
      cellClassName: "actions",
      getActions: (params) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const BasketID = params.row.BasketID;
        return [
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick({ BasketID })} color="inherit" disabled={!IsStaff} />,
        ];
      },
    },
  ];

  return (
    <>
      <CustomTableContainer>
        <TitleAndMenu
          type="B"
          refetch={refetch}
          AddButton={<CreateBasketButton />}
          pdfDownLoadButton
          objectCodeList={rowSelectionModel}
          title="바구니 목록"
        />
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          sx={dataGridStyle}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rows={rows ? rows : []}
          loading={isFetching}
          initialState={initialState}
          columns={columns.map((item) => ({ ...item, cellClassName: "cell-divider" }))}
          rowModesModel={rowModesModel}
          disableColumnFilter
          disableDensitySelector
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: GridToolbar,
            pagination: CustomPagination,
          }}
          slotProps={{
            columnsManagement: {
              getTogglableColumns,
            },
            toolbar: { setRowModesModel, ...otherToolbar },
          }}
        />
      </CustomTableContainer>
    </>
  );
}
