import Users from "../pages/Users";
import Basket from "../pages/Basket";
import Rack from "../pages/Rack";
import OrderList from "../pages/OrderList";
import Test from "../pages/Test";
import TotalOrderList from "../pages/TotalOrderList";
import CustomerFindOrder from "../pages/CustomerFindOrder";
import CoupangSalesSummary from "../pages/CoupangSalesSummary";
import MallSalesSummary from "../pages/MallSalesSummary";
import RocketFind from "../pages/RocketFind";
import MoveInventory from "../pages/MoveInventory";
import BarCodeAlert from "../pages/BarCodeAlert";
import NewProduct from "../pages/NewProduct";
import TargetFind from "../pages/TargetFind";

export interface NavItem {
  title: string;
  link?: string;
  element?: JSX.Element;
  children?: NavItem[];
}

export const nav_items2: NavItem[] = [
  {
    title: "회원",
    children: [
      {
        title: "회원관리",
        link: "users",
        element: <Users />,
      },
    ],
  },
  {
    title: "창고관리",
    children: [
      {
        title: "랙",
        link: "rack",
        element: <Rack />,
      },
      {
        title: "바구니",
        link: "basket",
        element: <Basket />,
      },
      {
        title: "재고이동",
        link: "move-inventory",
        element: <MoveInventory />,
      },
    ],
  },
  {
    title: "상품관리",

    children: [
      {
        title: "상품목록",
        link: "new-product-list",
        element: <NewProduct />,
      },
      {
        title: "바코드 알림",
        link: "barcode-alert",
        element: <BarCodeAlert />,
      },
    ],
  },
  {
    title: "발주관리",

    children: [
      {
        title: "발현",
        link: "total-order-list",
        element: <TotalOrderList />,
      },
      {
        title: "발주서목록",
        link: "orderlist",
        element: <OrderList />,
      },
    ],
  },
  // {
  //   title: "입고관리",
  //   children: [
  //     {
  //       title: "입고서목록",
  //       link: "newStore",
  //       element: <StoreList />,
  //     },
  //     {
  //       title: "오더관리",
  //       link: "chungo",
  //       element: <Chungdo />,
  //     },
  //   ],
  // },
  {
    title: "찾기",
    children: [
      {
        title: "임의찾기",
        link: "target-Find",
        element: <TargetFind />,
      },
      {
        title: "로켓찾기",
        link: "rocket-find",
        element: <RocketFind />,
      },
    ],
  },
  {
    title: "매출집계",
    children: [
      {
        title: "쿠팡",
        link: "coupang-sales-summary",
        element: <CoupangSalesSummary />,
      },
      {
        title: "자사몰",
        link: "mall-sales-summary",
        element: <MallSalesSummary />,
      },
    ],
  },
  {
    title: "고객주문관리",
    link: "customer-order-finder",
    element: <CustomerFindOrder />,
  },

  {
    title: "개발",
    children: [
      {
        title: "테스트",
        link: "test",
        element: <Test />,
      },
    ],
  },
];
