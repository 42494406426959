import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, MenuItem, Stack, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { useRef, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { customToast } from "../../../../shared/lib/customToast";
import { useGetCustomerFindOrderList, useUpdateCustomerFindOrder } from "../../../../service/customer-find-order/customerFindOrderHooks";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { QUERY_KEY } from "../../../../config/querykey";
import { useQueryClient } from "@tanstack/react-query";
import { useCustomerBrandStore } from "../../../../store/customerBrandStore";
import { getCustomerOrderListResponseDto } from "../../../../service/customer-find-order/types";

export const UploadInvoiceFileButton: React.FC = () => {
  const queryClient = useQueryClient();
  const { brandStr, startDate, endDate } = useCustomerBrandStore((v) => v);
  const data = queryClient.getQueryData([QUERY_KEY.customerFindOrder, brandStr, startDate, endDate]) as getCustomerOrderListResponseDto[];
  const [open, setOpen] = useState<boolean>(false);

  //업데이트하면전부 새로고침
  const { mutate: updateCustomerFindOrder } = useUpdateCustomerFindOrder((data) => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.customerFindOrder] });
  });
  const handleChangeOpen = () => {
    setOpen(!open);
  };

  const handleUploadInvoice = (files: FileList | null) => {
    const file = files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // const excelData = new Uint8Array(e.target?.result as ArrayBuffer);
        // const workbook = XLSX.read(excelData, { type: "array" }); // xlsx 일때
        const text = e.target?.result as string; // 파일
        const workbook = XLSX.read(text, { type: "binary", bookVBA: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        //{품목별 주문번호 :운송장 번호}
        const list = data // 화면에 보이는 고객주문 데이터
          ? data
              .filter((v) => !!v.BarCode)
              .reduce((acc: { [key: string]: string }, item: any) => {
                acc[item.OrderNumber_idx] = item.CustomerOrderID;
                return { ...acc };
              }, {})
          : {};

        if (Object.keys(list).length !== 0) {
          //만들기 만들기
          const result: any[] = rows // row === 엑셀데이터
            .slice(1)
            .filter((v) => list[v[1]]) // 품목별 주문번호가 겹치는것만 뽑기
            .map((item) => {
              return {
                CustomerOrderID: list[item[1]], // 연번
                CJ_Number: item[2], //운송장 번호
              };
            });

          //만들어진 송장번호를 서버로 보내기
          if (result.length !== 0) {
            updateCustomerFindOrder(result);
            // customToast("error", "엑셀파일을 확인해주세요. 없는 송장 번호가 존재합니다. ");
          }
        } else {
          customToast("error", "등록할 고객주문 항목이 없습니다.");
        }
        InvoiceUploadRef!.current!.value = "";
      };
      reader.readAsArrayBuffer(file);
    } else {
      customToast("warn", "엑셀을 선택해 주세요.");
    }

    setOpen(false);
  };

  const handleDownLoad = () => {
    window.location.href = "https://paperlife.kr/template/invoice_registration_form.csv";
  };
  const InvoiceUploadRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <MenuItem onClick={handleChangeOpen}>
        <ListItemIcon>
          <FileUploadIcon />
        </ListItemIcon>
        송장등록
      </MenuItem>
      <Dialog open={open} onClose={handleChangeOpen}>
        <DialogTitle>송장등록</DialogTitle>

        <DialogContent>
          <Typography>송장번호를 확인해주세요!</Typography>
          <Stack gap={2}>
            <label htmlFor="uploadInvoice">
              <Button component="span" variant="contained" sx={{ width: "250px", height: "80px" }} tabIndex={-1} startIcon={<FileUploadIcon />}>
                송장등록
              </Button>
            </label>
            <input
              ref={InvoiceUploadRef}
              style={{ display: "none" }}
              id="uploadInvoice"
              type="file"
              accept=".csv"
              onChange={(e) => handleUploadInvoice(e.target.files)}
            />
            <Button variant="outlined" sx={{ width: "250px", height: "80px" }} onClick={handleDownLoad} startIcon={<InsertDriveFileOutlinedIcon />}>
              양식 다운로드
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleChangeOpen}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
