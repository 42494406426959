import { Card, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import SalesPieChart from "../entities/sales-summary/ui/SalesPieChart";
import { useEffect, useMemo, useState } from "react";
import { brandIconOptions, BrandIconType } from "../entities/side-navigation/const/brandIconsOption";
import DateRangeButton from "../entities/sales-summary/ui/DateRangeButton";
import SalesDataTable from "../entities/sales-summary/ui/SalesDataTable";
import RefetchButton from "../entities/sales-summary/ui/RefetchButton";
import ValueCard from "../entities/sales-summary/ui/ValueCard";
import { useGetBrandSalesSummary, useGetTotalSalesSummary } from "../service/sales-summary/salesSummaryHooks";
import { formatDate, useDateRangeStore } from "../store/DateRangeStore";
import SalesPriceLineChart from "../entities/sales-summary/ui/SalesPriceLineChart";
import SalesQuantityLineChart from "../entities/sales-summary/ui/SalesQuantityLineChart";
import { useUserInfoState } from "../store/UserInfoStore";
import { useNavigate } from "react-router-dom";
import AnimationWrapper from "../entities/sales-summary/ui/AnimationWrapper";
import RowStack from "../shared/ui/RowStack";

const CoupangSalesSummary: React.FC = () => {
  const [brand, setBrand] = useState("PA");
  const navigate = useNavigate();
  const { RoleID } = useUserInfoState((v) => v);
  const { endDate, startDate } = useDateRangeStore((v) => v.term);
  const { data: totalSalesSummary, refetch: totalRefetch } = useGetTotalSalesSummary({
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  });
  const { data: brandSalesSummary, refetch: brandRefetch } = useGetBrandSalesSummary({
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    brand,
  });

  useEffect(() => {
    if (RoleID !== 100) {
      navigate("/");
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setBrand(newValue);
  };

  //icon map
  const brandIconMap: { [key: string]: string } = useMemo(() => {
    let result: any = {};

    brandIconOptions.forEach((v: BrandIconType) => {
      result[v.brandCode] = v.imagePath;
    });
    return result;
  }, [brandIconOptions]);

  //brand name map
  const brandNameMap: { [key: string]: string } = useMemo(() => {
    let result: any = {};

    brandIconOptions.forEach((v: BrandIconType) => {
      result[v.brandCode] = v.brandName;
    });
    return result;
  }, [brandIconOptions]);

  const totalCount = useMemo(() => {
    const result: number[] = [0, 0, 0, 0];
    if (brandSalesSummary?.AllInfo) {
      Object.keys(brandSalesSummary.AllInfo).map((key: any) => {
        result[0] += brandSalesSummary.AllInfo[key].QuotedPrice;
        result[1] += brandSalesSummary.AllInfo[key].RocketMargin;
        result[2] += brandSalesSummary.AllInfo[key].Quantity;
        result[3] += brandSalesSummary.AllInfo[key].Quantity_OK;
      });
    }

    return result;
  }, [brandSalesSummary]);

  return (
    <>
      <Stack sx={{ width: "1200px", margin: "0 auto", my: 10 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <RowStack gap={2}>
                <Typography fontWeight="bold" sx={{ marginRight: "auto" }}>
                  브랜드별 비교분석 통계
                </Typography>
                <DateRangeButton />
                <RefetchButton
                  refetch={() => {
                    totalRefetch();
                  }}
                />
              </RowStack>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <AnimationWrapper>
              <SalesPieChart
                colorType={1}
                unit="원"
                title="브랜드별 매출"
                data={
                  totalSalesSummary
                    ? Object.keys(totalSalesSummary).map((key) => ({ brand: key, value: totalSalesSummary[key].total_QuotedPrice }))
                    : []
                }
              />
            </AnimationWrapper>
          </Grid>
          <Grid item xs={4}>
            <AnimationWrapper delay={1}>
              <SalesPieChart
                colorType={1}
                unit="원"
                title="브랜드별 마진"
                data={
                  totalSalesSummary
                    ? Object.keys(totalSalesSummary).map((key) => ({ brand: key, value: totalSalesSummary[key].total_RocketMargin }))
                    : []
                }
              />
            </AnimationWrapper>
          </Grid>
          <Grid item xs={4}>
            <AnimationWrapper delay={2}>
              <SalesPieChart
                colorType={1}
                unit="개"
                title="브랜드별 확정수량"
                data={
                  totalSalesSummary
                    ? Object.keys(totalSalesSummary).map((key) => ({ brand: key, value: totalSalesSummary[key].total_QuantityOK }))
                    : []
                }
              />
            </AnimationWrapper>
          </Grid>
          <Grid item xs={12}>
            <Tabs value={brand} onChange={handleChange} variant="fullWidth" centered sx={{ mt: 10 }}>
              {brandIconOptions.map((brand) => (
                <Tab key={brand.brandCode} value={brand.brandCode} label={brand.brandName} sx={{ fontSize: "14px" }} />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" alignItems={"center"} sx={{ backgroundColor: "white" }} gap={2}>
                <img src={brandIconMap[brand]} alt={brand} width={50} draggable="false" />
                <Typography fontWeight="bold" sx={{ marginRight: "auto" }}>
                  {brandNameMap[brand]}
                </Typography>
                <DateRangeButton />
                <RefetchButton
                  refetch={() => {
                    brandRefetch();
                  }}
                />
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={3}>
            <ValueCard title="총 매출액" value={totalCount[0]} unit="원" />
          </Grid>
          <Grid item xs={3}>
            <ValueCard title="총 마진" value={totalCount[1]} unit="원" />
          </Grid>
          <Grid item xs={3}>
            <ValueCard title="총 발주수량" value={totalCount[2]} unit="개" />
          </Grid>
          <Grid item xs={3}>
            <ValueCard title="총 확정수량" value={totalCount[3]} unit="개" />
          </Grid>

          <Grid item xs={12}>
            <AnimationWrapper delay={0}>
              <SalesPriceLineChart colorType={1} data={brandSalesSummary ? brandSalesSummary.DateInfo : {}} />
            </AnimationWrapper>
          </Grid>
          <Grid item xs={12}>
            <AnimationWrapper delay={1}>
              <SalesQuantityLineChart colorType={1} data={brandSalesSummary ? brandSalesSummary.DateInfo : {}} />
            </AnimationWrapper>
          </Grid>
          <Grid item xs={12}>
            <AnimationWrapper delay={0}>
              <SalesDataTable data={brandSalesSummary ? brandSalesSummary.AllInfo : []} brandName={brandNameMap[brand]} brandCode={brand} />
            </AnimationWrapper>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default CoupangSalesSummary;
