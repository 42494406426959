import { Grid, Card, Stack, Chip, Tooltip, IconButton, Typography, Divider } from "@mui/material";
import { useModals } from "../../../service/modal/useModals";
import { getRocketFindListResponseDto } from "../../../service/rocket/types";
import { ShowInventoryModal } from "../../../widget/show-inventory-modal/ui/ShowInventoryModal";
import LabelWithContent from "./LabelWithContent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClicpboard } from "../lib/copyToClipBoard";

interface RocketItemProps {
  data: getRocketFindListResponseDto;
}

export const RocketItem: React.FC<RocketItemProps> = ({ data }) => {
  const { BarCode, Color, ProductName, Quantity, RackID, Size } = data;
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowInventoryModal);
  };

  const handleClickOpen = () => {
    openModal(ShowInventoryModal, { BarCode, handleClose, status });
  };

  const handleCopyClipboard = () => {
    copyToClicpboard(BarCode);
  };

  return (
    <Grid item xs={3}>
      <Card
        sx={{
          p: 6,
          backgroundColor: "#fff",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.2s ease-in-out",
          "&:hover": {
            boxShadow: "0 8px 12px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Stack gap={5}>
          <Stack gap={2} sx={{ height: "120px" }} alignItems={"start"}>
            <Stack direction={"row"} gap={1} alignItems={"center"} sx={{ width: "100%" }}>
              {/* <Chip sx={{ px: 1 }} label={BarCode} variant="outlined" color="primary" /> */}
              <Typography fontSize={"18px"} color="primary">
                {BarCode}
              </Typography>
              <Tooltip title={<p>클릭하면 바코드가 복사됩니다.</p>} placement="top" arrow>
                <IconButton onClick={handleCopyClipboard}>
                  <ContentCopyIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography variant="h6" color="#333">
              {ProductName}
            </Typography>
          </Stack>
          <Divider />

          <Grid container>
            <Grid item xs={12}>
              <LabelWithContent label="색상" content={Color} />
            </Grid>
            <Grid item xs={12}>
              <LabelWithContent label="사이즈" content={Size} />
            </Grid>

            <Grid item xs={12}>
              <LabelWithContent label="찾을 개수" content={Quantity} />
            </Grid>
            <Grid item xs={12} onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
              <LabelWithContent label="대표랙" content={RackID} contentColor="#0b57d0" contentSx={{ textDecoration: "underline" }} />
            </Grid>
          </Grid>
        </Stack>
      </Card>
    </Grid>
  );
};

export default RocketItem;
