import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getTotalOrderListResponseDto,
  updateTotalOrderListMemoDto,
  searchProductResponseDto,
  searchProductRequsetDto,
  updateFulfillmentsDto,
  getProductListInToteRequestDto,
  getProductListinToteResponseDto,
  moveTableToBasketDto,
  getIncommingProductByBarCodeRequestDto,
  getIncommingProductByBarCodeResponseDto,
  getBoxNameListByOrderItemIdRequestDto,
  getBoxNameListByOrderItemIdResponseDto,
} from "./types";
import { QUERY_KEY } from "../../config/querykey";
import { totalOrderListApi } from "./totalOrderListAxios";
import { displayErrorMessage } from "../../shared/lib/alertErrorMessage";
import { customToast } from "../../shared/lib/customToast";

export const useGetTotalOrderList = (isEdit: boolean) => {
  return useQuery<getTotalOrderListResponseDto[], Error>({
    queryKey: [QUERY_KEY.totalOrderList],
    queryFn: () => totalOrderListApi.getTotalOrderList(),
    select: (data) => {
      return data.map((item: getTotalOrderListResponseDto) => ({
        ...item,
        Quantity_Error: item.Quantity_OK - item.Quantity_Ready,
      }));
    },
    staleTime: 0,
    refetchInterval: isEdit ? false : 1000 * 15,
  });
};

export const useUpdateTotalOrderListMemo = () => {
  return useMutation<updateTotalOrderListMemoDto, Error, updateTotalOrderListMemoDto>({
    mutationFn: (data: updateTotalOrderListMemoDto) => totalOrderListApi.updateTotalOrderListMemo(data),
  });
};

export const useSearchProduct = (sucess: (data: searchProductResponseDto) => void, error: () => void) => {
  return useMutation<searchProductResponseDto, Error, searchProductRequsetDto>({
    mutationFn: (data: searchProductRequsetDto) => totalOrderListApi.searchProduct(data),
    onSuccess: (data) => {
      sucess(data);
    },
    onError: (err) => {
      error();
    },
  });
};

export const useUpdateFulfillments = () => {
  const queryClient = useQueryClient();
  return useMutation<updateFulfillmentsDto, Error, updateFulfillmentsDto>({
    mutationFn: (data: updateFulfillmentsDto) => totalOrderListApi.updateFulfillments(data),
    onSuccess: (data: updateFulfillmentsDto) => {
      customToast("success", `[${data.ToObjectCode}]에 제품이 등록되었습니다.`);
      //개별 발주서의 준비수량을 변경해야하기 때문에 캐싱값 날리기
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.order] });
      //발주확정 성공시 Quantity_Ready, RemainingQuantity 변경
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "발주확정에 실패하였습니다.", error: err });
    },
  });
};

// 가져오기
export const useGetProductListInTote = (seter: (data: boolean) => void) => {
  return useMutation<getProductListinToteResponseDto[], Error, getProductListInToteRequestDto>({
    mutationFn: (data: getProductListInToteRequestDto) => totalOrderListApi.getProductListInTote(data),
    onSuccess: (data) => {
      if (data.length === 0) {
        seter(false);
      } else {
        seter(true);
      }
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "유효한 바구니정보가 아닙니다. ", error: err });
      seter(false);
    },
  });
};

export const useMoveTableToBasket = () => {
  const queryClient = useQueryClient();

  return useMutation<moveTableToBasketDto, Error, moveTableToBasketDto>({
    mutationFn: (data: moveTableToBasketDto) => totalOrderListApi.moveTableToBasket(data),
    onSuccess: (data) => {
      customToast("success", `바구니[${data.to}]에 제품이 등록되었습니다.`);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.basket] });
    },
    onError: (err) => {
      displayErrorMessage({ defaultErrorMessage: "바구니 이동에 실패하였습니다.", error: err });
    },
  });
};

//개인 바스킷 정보가져오기만 날리면 된다.

export const useGetIncommingProductByBarCode = (data: getIncommingProductByBarCodeRequestDto) => {
  return useQuery<getIncommingProductByBarCodeResponseDto[], Error>({
    queryKey: [QUERY_KEY.incoming, data.BarCode],
    queryFn: () => totalOrderListApi.getIncommingProductByBarCode(data),
  });
};

export const useGetBoxNameListByOrderItemId = (data: getBoxNameListByOrderItemIdRequestDto) => {
  return useQuery<getBoxNameListByOrderItemIdResponseDto[], Error>({
    queryKey: [QUERY_KEY.boxNameList, data.orderItemID],
    queryFn: () => totalOrderListApi.getBoxNameListByOrderItemId(data),
  });
};
