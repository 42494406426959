import React from "react";
import { useModals } from "../../../../service/modal/useModals";
import { CustomButton } from "../../../../shared/ui/buttom/CustomButton";
import { getCustomerOrderListResponseDto } from "../../../../service/customer-find-order/types";
import { ShowOrderPeopleModal } from "./ShowOrderPeopleModal";
import AutoMultiCell from "../../../../shared/ui/grid-cell/AutoMultiCell";

interface Props {
  data: getCustomerOrderListResponseDto;
}

const ShowOderPeopleButton: React.FC<Props> = ({ data }) => {
  const { closeModal, openModal } = useModals();

  const handleClose = () => {
    closeModal(ShowOrderPeopleModal);
  };

  const handleClickOpen = () => {
    openModal(ShowOrderPeopleModal, { data, handleClose });
  };

  return (
    <React.Fragment>
      <AutoMultiCell>
        <CustomButton
          onClick={handleClickOpen}
          // onMouseEnter={handleClickOpen}
        >
          {data.OrderNumber_idx}
        </CustomButton>
      </AutoMultiCell>
    </React.Fragment>
  );
};

export default ShowOderPeopleButton;
