export const getColor = (type: number, idx: number) => {
  const color_list1 = ["#0073E6 ", "#338FE9 ", "#66ABE6 ", "#99C7E9 ", "#CCE3F0 ", "#E5F1F7 ", "#F0F8FC ", "#F3FBFE ", "#F7FDFF "];
  const color_list2 = ["#FF6384", "#FF7996", "#FF8EA7", "#FFA3B9", "#FFB8CB", "#FFCDDD", "#FFDDE6", "#FFE6EF", "#FFF0F5"];

  if (type === 1) {
    return color_list1[Math.max(0, Math.min(idx, color_list2.length - 1))];
  } else {
    return color_list2[Math.max(0, Math.min(idx, color_list2.length - 1))];
  }
};
