import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type brandCodeType = {
  BrandCode: string;
};

type BrandCodeActions = {
  setBrandCode: (data: string) => void;
  clearBrandCode: () => void;
  getBrandCode: () => brandCodeType;
};

export const useBrandCodeStore = create(
  persist<brandCodeType & BrandCodeActions>(
    (set, get) => ({
      BrandCode: "ME",
      setBrandCode: (data: string) => {
        set({
          BrandCode: data,
        });
      },
      clearBrandCode: () => {
        set({
          BrandCode: "",
        });
      },
      getBrandCode: () => {
        return {
          BrandCode: get().BrandCode,
        };
      },
    }),
    {
      name: "ATTERLIC_BRAND_INFO",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const { clearBrandCode, getBrandCode, setBrandCode } = useBrandCodeStore.getState();
