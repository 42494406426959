import { Dialog, DialogTitle, DialogContent, Typography, Stack, TextField, Button, DialogActions, Box, Card } from "@mui/material";
import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import { useBoxNameStore } from "../../../../store/boxNameStore";
import ProductInfo from "../ProductInfo";
import { searchProductResponseDto } from "../../../../service/total-order/types";
import { useUpdateFulfillments } from "../../../../service/total-order/totalOrderListHooks";
import { totalOrderListApi } from "../../../../service/total-order/totalOrderListAxios";
import { customToast } from "../../../../shared/lib/customToast";
import { CountTextStyle } from "../../style/style";
import { speakText } from "../../lib/speekText";
import { useHistoryStore } from "../../../../store/totalOrderHIstory";
import { useUserInfoState } from "../../../../store/UserInfoStore";

interface FlexBoxModalProps {
  open: boolean;
  onClose: () => void;
  info: searchProductResponseDto;
}

export const FlexBoxModal: React.FC<FlexBoxModalProps> = ({ open, onClose, info }) => {
  const { ToType, OrderItemID, OrderID, BarCode, Quantity_Rest: limit } = info; //바코드로 찍은후 넘어온 제품 정보
  const countRef = useRef<HTMLInputElement>(null); //
  const boxNameRef = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [boxName, setBoxName] = useState<string>("");
  const { boxInfo, setBoxInfo, deleteBoxName } = useBoxNameStore((status) => status);
  const { mutate: updateFulfillments } = useUpdateFulfillments();

  const { setHistory } = useHistoryStore((v) => v);
  const { UserName } = useUserInfoState((v) => v);

  const type = useMemo(() => {
    return info?.OrderAlias?.includes("사전확정") ? "preConfirm" : ToType;
  }, [info.OrderAlias]);

  // 포커스를 할 때 모달이 열리는 시간을 생각해서 약간의 딜레이가 필요하다. 모달이 열리는 순간 포커스를 하면 포커스가 씹힐 수 있다.
  const dilayFocus = useCallback((ref: any) => {
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, []);

  // 바구니 번호 입력 후 엔터키 입력시 수량입력으로 포커싱 됨
  const handleKeyDwonEnter = (e: any) => {
    if (e.key === "Enter") {
      handleSubmitBoxName();
    }
  };

  //시작 삭제를 등록하는 함수
  const handleCountKeyDwon = (e: any) => {
    if (e.key === "E") {
      handleSubmit();
    }

    if (e.key === "C") {
      setCount(0);
    }
  };

  // 바구니에 넣는 등록함수
  const handleSubmit = () => {
    if (count !== 0) {
      updateFulfillments({
        OrderID,
        OrderItemID,
        Quantity: count,
        ToType,
        ToObjectCode: boxName,
        BarCode,
      });

      //히스토리에 저장
      setHistory({
        OrderID: OrderID,
        OrderAlias: info.OrderAlias,
        BarCode: BarCode,
        CreatedAt: new Date().toLocaleString(),
        CreatedBy: UserName,
        Count: count,
        BasketID: boxName,
        ProductName: info.ProductName,
        Size: info.Size,
        Color: info.Color,
        Type: ToType,
      });

      onClose();
    } else {
      customToast("warn", "수량을 입력해주세요.");
    }
  };

  //상자이름을 등록하는 함수
  const handleSubmitBoxName = () => {
    /**
     * 1. 바구니 이름이 유효한지 확인
     * 2. 유효하다면 전역변수에 등록하고 수량 입력으로 포커싱
     * 3. disabled 변경
     * 4. 유효하지 않다면 alert
     */

    const regex = type === "flex" ? /^535-RCRT\d-\d+-\d+$/ : /^444-([1-9]|0[1-9]|1[0-9]|2[0-9]|30)$/;

    if (regex.test(boxName)) {
      totalOrderListApi
        .getProductListInTote({ tote: boxName })
        .then((data) => {
          if (data.length === 0) {
            dilayFocus(countRef);
            setBoxInfo({ type, boxName });
            setDisabled(false);
          } else {
            if (window.confirm("이미 사용중인 토트번호입니다. 사용하시겠습니까?")) {
              dilayFocus(countRef);
              setBoxInfo({ type, boxName });
              setDisabled(false);
            } else {
              setBoxName("");
            }
          }
        })
        .catch((err) => {
          customToast("warn", "사용할 수 없는 토트번호입니다.");
          setBoxName("");
        });
    } else {
      customToast("warn", "사용할 수 없는 토트번호입니다.");
      setBoxName("");
    }
  };

  const handeChangeBoxName = () => {
    /**
     * 1. 등록된 바구니의 이름을 변경
     * 2. 버튼을 누르면 전역변수에 있는 바구니 이름 삭제
     * 3. boxName 초기화
     * 4. boxNameRef에 포커싱
     * 5. disabled를 false로 변경
     */

    deleteBoxName(type);
    setBoxName("");
    setCount(0);
    setDisabled(true);
    dilayFocus(boxNameRef);
  };

  // 수량 입력시 숫자만 입력되도록 함
  const handleCountChange = (e: any) => {
    /**
     * 1. ENTER 입력시 => 등록버튼 클릭
     * 2. CLEAR 입력시 => 수량 삭제
     * 3. 숫자 입력시 => 수량 입력
     * 4. 다른 번호 입력시 => 이전 수량 유지
     *
     * 문제점 : 스캐너라 스케너라 할지라도 차례대로 입력되는건데 enter 가 그대로 유지될 수 있을까?
     */

    const value = e.target.value || 0;
    if (!isNaN(parseInt(value))) {
      if (parseInt(value) > limit) customToast("warn", "남은 수량보다 많은 수량을 입력하셨습니다.");
      const result = Math.max(0, Math.min(limit, parseInt(value)));
      speakText(result);
      setCount(result);
    }
  };

  useEffect(() => {
    if (open) {
      setCount(0);
      // 등록된 바구니 이름이 없다면

      if (!boxInfo[type]) {
        /**
         * 1. 바구니 input 에 포커싱
         * 2. disabled를 true로 변경하여 수량 변경 못하게 막기
         * 3. boxName 초기화
         */
        dilayFocus(boxNameRef);
        setBoxName("");
        setDisabled(true);
      } else {
        /**
         * 1. 수량 input 에 포커싱
         * 2. disabled를 false로 변경하여 수량변경 가능하게 하기
         * 3. boxName 전역변수에 있는 값으로 초기화
         */
        setBoxName(boxInfo[type]);
        setDisabled(false);
        dilayFocus(countRef);
      }
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "390px",
            },
          },
        }}
      >
        <Box sx={{ backgroundColor: type === "preConfirm" ? "red" : "#0068D0", height: "30px" }} />
        <DialogTitle
          id="alert-dialog-title"
          fontSize={"18px"}
          fontWeight={"bold"}
          sx={info?.OrderAlias?.includes("사전확정") ? { color: "red", fontWeight: "bold" } : { color: "#0068D0" }}
        >
          플랙스 박스 [{info.OrderAlias ? info.OrderAlias : "-"}]
        </DialogTitle>
        <DialogContent>
          <ProductInfo data={info} />
          <Typography variant="subtitle1" color={!boxInfo[type] ? "error" : "primary"} sx={{ my: 5 }}>
            * {!boxInfo[type] ? "토트 번호를 입력해주세요." : `남은 수량 : ${limit}개`}
          </Typography>
          <Stack direction="row" gap={1} sx={{ mb: 2 }}>
            <TextField
              inputRef={boxNameRef}
              id="boxNumber"
              size="small"
              fullWidth
              disabled={!disabled}
              label="토트 번호"
              placeholder="토트 번호를 입력해주세요."
              value={boxName}
              onChange={(e) => setBoxName(e.target.value)}
              onKeyDown={handleKeyDwonEnter}
            />

            {!disabled ? (
              <Button variant="outlined" onClick={handeChangeBoxName}>
                변경
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSubmitBoxName}>
                등록
              </Button>
            )}
          </Stack>

          <Stack direction="row" gap={1}>
            <TextField
              inputProps={{
                style: CountTextStyle,
              }}
              disabled={disabled}
              inputRef={countRef}
              size="small"
              fullWidth
              label="수량"
              placeholder="수량을 입력해주세요."
              value={count}
              onKeyDown={handleCountKeyDwon}
              onChange={handleCountChange}
            />
            <Button
              sx={{ fontSize: "32px" }}
              disabled={disabled}
              variant="outlined"
              onClick={() => {
                setCount((prev) => (prev + 1 > limit ? limit : prev + 1));
              }}
            >
              +
            </Button>
            <Button
              disabled={disabled}
              sx={{ fontSize: "32px" }}
              variant="outlined"
              onClick={() => {
                setCount((prev) => (prev === 0 ? 0 : prev - 1));
              }}
            >
              -
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button disabled={disabled} onClick={handleSubmit} variant="contained">
            등록
          </Button>
          <Button onClick={onClose} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
