import TargetFindTable from "../entities/fine-order/ui/TargetFindTable";

const TargetFind = () => {
  return (
    <>
      <TargetFindTable />
    </>
  );
};

export default TargetFind;
