import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, TextField, Button, DialogActions, Grid } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useExcelData } from "../../../../service/order/orderContext";
import { createOrderRequestDto } from "../../../../service/order/types";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCreateOrderList, useGetOrderList } from "../../../../service/order/orderHooks";
import { useBrandCodeStore } from "../../../../store/brandStore";
import { customToast } from "../../../../shared/lib/customToast";
import dayjs, { Dayjs } from "dayjs";
import UploadOrderFileButton from "./UploadOrderFileButton";
import { brandIconOptions } from "../../../side-navigation/const/brandIconsOption";

interface AddTotalOrderModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

export const CreateOrderModal: React.FC<AddTotalOrderModalProps> = ({ open, handleClose }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { excelData, setExcelData } = useExcelData();
  const BrandCode = useBrandCodeStore((s) => s.BrandCode);
  const { mutateAsync: createOrderList } = useCreateOrderList();
  const { refetch } = useGetOrderList({ brand: BrandCode });
  const [aliases, setAliases] = useState<any>({});
  const [orderDate, setOrderDate] = React.useState<null | string | undefined>(null);
  const [date, setDate] = React.useState<Dayjs | null>(null);

  const handleDateChange = (newDate: Dayjs | null) => {
    const formatValue = newDate?.format("YYYY-MM-DD");
    if (!!formatValue && formatValue > "2024-01-01") {
      setOrderDate(formatValue);
    } else {
      customToast("warn", "[2024-01-01 이전 날짜는 입력할 수 없습니다.]");
      setOrderDate("");
    }
  };

  //발주서 닉네임 생성 함수들
  const formatnicknameDate = (date: string | number) => {
    if (typeof date === "number") {
      date = date.toString();
    }
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}${month}${day}`;
  };
  // 날짜 값을 'YYYY-MM-DD' 형식으로 변환하는 함수
  const formatDate = (date: string | number) => {
    if (typeof date === "number") {
      date = date.toString();
    }
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    return `${year}-${month}-${day}`;
  };

  //브랜드코드를 브랜드 대표 글자와 연결시키는 객체
  const brandCodeToFirstCharMap: { [key: string]: string } = useMemo(() => {
    return brandIconOptions.reduce((acc, v) => {
      const key = v.brandCode;
      const value = v.firstChar;
      return { ...acc, [key]: value };
    }, {});
  }, [brandIconOptions]);

  useEffect(() => {
    //발주서 이름생성
    const createAlias = (data: any[]) => {
      let alias = "";
      if (data.length > 0) {
        const firstData = data[0];
        let centerFirstChar = "";
        let firstBrandChar = "";

        let Date = formatnicknameDate(firstData.receiveDate);

        if (firstData.receiveDate) {
          const receiveDateMonth = Date.substring(4, 6);
          const receiveDateDate = Date.substring(6, 8);
          const regex = /[^0-9]/g;
          const centerLastChar = firstData.center.replace(regex, "");

          firstBrandChar = brandCodeToFirstCharMap[BrandCode] || "";

          if (firstData.center === "VF150") {
            centerFirstChar = "플1";
            alias = `${firstBrandChar}${receiveDateMonth}${receiveDateDate}${centerFirstChar}`;
          } else {
            centerFirstChar = firstData.center.charAt(0);
            alias = `${firstBrandChar}${receiveDateMonth}${receiveDateDate}${centerFirstChar}${centerLastChar}`;
          }
        }
      }
      return alias;
    };

    const initialAliases: { [center: string]: { [receiveDate: string]: string } } = {};

    Object.keys(excelData).forEach((center: any) => {
      const receiveDateGroups = excelData[center];
      initialAliases[center] = {};

      Object.keys(receiveDateGroups).forEach((receiveDate: any) => {
        const data = receiveDateGroups[receiveDate];
        initialAliases[center][receiveDate] = createAlias(data);
      });
    });

    setAliases(initialAliases);
  }, [excelData]);

  useEffect(() => {
    if (excelData) {
      const formatDate = dayjs().format("YYYY-MM-DD");
      setOrderDate(formatDate);
      setDate(dayjs());
    }
  }, [excelData]);

  const handleChangeOrderAlias = (newAlias: string, center: string, receiveDate: string) => {
    setAliases((prevAliases: any) => ({
      ...prevAliases,
      [center]: {
        ...prevAliases[center],
        [receiveDate]: newAlias,
      },
    }));
  };

  const handleCreateOrderSubmit = async () => {
    if (window.confirm(`발주서를 추가하시겠습니까?`)) {
      const transformed = (data: any[], center: string, receiveDate: string): createOrderRequestDto | null => {
        if (!center) {
          customToast("warn", "센터가 없습니다.");
          return null;
        }

        const alias = aliases[center] && aliases[center][receiveDate] ? aliases[center][receiveDate] : "";
        const isFlex = center === "VF150" ? true : false;

        return {
          OrderDate: orderDate ? orderDate : "",
          OrderAlias: alias,
          Status: "R",
          ReceiveDate: formatDate(receiveDate),
          Center: center,
          ResponsiblePerson: "",
          ReceiveMethod: "",
          Brand: BrandCode,
          IsFlex: isFlex,
          items: data.map((item) => ({
            IndividualOrderNumber: item.orderNumber || "",
            Quantity: item.orderQuantity || 0,
            Quantity_OK: item.confirmQuantity || 0,
            Quantity_Ready: "0",
            Status: "R",
            SupplyPrice: item.purchasePrice || 0, // 공급가 추가
            BarCode: item.productBarCode || "",
            SKU_ID: item.productNumber || "",
          })),
        };
      };

      const centerDataGroups = excelData;
      const transformedDataList = Object.keys(centerDataGroups)
        .flatMap((center: any) =>
          Object.keys(centerDataGroups[center]).map((receiveDate: any) => {
            const data = centerDataGroups[center][receiveDate];
            return transformed(data, center, receiveDate);
          })
        )
        .filter((data) => data !== null) as createOrderRequestDto[];

      // '사전확정' 포함 여부 확인
      const hasInvalidData = Object.keys(centerDataGroups).some((center: any) =>
        Object.keys(centerDataGroups[center]).some((receiveDate: any) => {
          const alias = aliases[center] && aliases[center][receiveDate] ? aliases[center][receiveDate] : "";
          return center.includes("사전확정") || alias.includes("사전확정");
        })
      );

      if (hasInvalidData) {
        customToast("error", "[사전확정]은 사용할 수 없는 단어입니다. 다시 입력해주세요.");
      }
      createOrderList(transformedDataList); // 여기서 성공 실패 여부 처리
      handleClose();
    } else {
      customToast("warn", "모든 정보를 입력해주세요.");
    }
  };

  const handleCloseModal = () => {
    refetch();
    setExcelData([]);
    handleClose();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">발주서 추가</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "flex-end" }}>
          <UploadOrderFileButton />
        </DialogContent>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ mb: 4 }}>
            <Typography variant="subtitle2">발주서 정보를 모두 입력해주세요.</Typography>
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="발주일"
                  format="YYYY-MM-DD"
                  value={date ? date : null}
                  disableHighlightToday={true}
                  onChange={handleDateChange}
                  sx={{ width: "396px" }}
                />
              </LocalizationProvider>
            </Grid>

            {Object.keys(excelData).map((center: any) =>
              Object.keys(excelData[center]).map((receiveDate) => (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={`${center} 발주서 별명`}
                    ref={inputRef}
                    id="OrderAlias"
                    placeholder="ex) 비0712고1"
                    value={(aliases[center] && aliases[center][receiveDate] ? aliases[center][receiveDate] : "") || ""}
                    onChange={(e) => handleChangeOrderAlias(e.target.value, center, receiveDate)}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleCreateOrderSubmit} autoFocus variant="contained">
            등록
          </Button>
          <Button onClick={handleCloseModal} variant="outlined">
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateOrderModal;
