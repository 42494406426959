import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, ListItemIcon, MenuItem, Card, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useCustomerBrandStore } from "../../../../store/customerBrandStore";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../../config/querykey";
import { getCustomerOrderListResponseDto } from "../../../../service/customer-find-order/types";
import { LineChart } from "@mui/x-charts/LineChart";
import RowStack from "../../../../shared/ui/RowStack";
import { cumulativeSum } from "../../../../shared/lib/cumulativeSum";

export const MarginSummaryButton = () => {
  const { brandStr, endDate, startDate } = useCustomerBrandStore((v) => v);
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([QUERY_KEY.customerFindOrder, brandStr, startDate, endDate]) as getCustomerOrderListResponseDto[];

  const { totalMargin, totalSales, totalQuantity, period, dateList, valueList } = useMemo(() => {
    let totalMargin = 0;
    let totalSales = 0;
    let totalQuantity = 0;
    let period = `${startDate} ~ ${endDate}`;

    const dates = getDateRange(startDate, endDate);

    let marginGraphData = dates.reduce((acc: any, cur: string) => {
      acc[cur] = 0;
      return acc;
    }, {});

    if (data) {
      data
        .filter((v) => v.StatusMeno === "" || v.StatusMeno === "5.발송완료" || v.StatusMeno === "6.교환진행")
        .forEach((v: getCustomerOrderListResponseDto) => {
          const { Quantity_Target, Price, 마진, OrderNumber } = v;
          totalMargin += 마진 * Quantity_Target;
          totalSales += Price * Quantity_Target;
          totalQuantity += Quantity_Target;
          const date = OrderNumber.slice(0, 8);
          const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
          if (marginGraphData[formattedDate] === undefined) {
            marginGraphData[formattedDate] = 0;
          }
          marginGraphData[formattedDate] += Price * Quantity_Target || 0;
        });
    }

    const dateList: any[] = Object.keys(marginGraphData) || [];
    const valueList: any[] = Object.values(marginGraphData) || [];
    return { totalMargin, totalSales, totalQuantity, period, dateList, valueList };
  }, [data, brandStr, startDate, endDate]);

  const [open, setOpen] = useState<boolean>(false);
  const handleChangeOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <MenuItem onClick={handleChangeOpen}>
        <ListItemIcon>
          <CalculateIcon />
        </ListItemIcon>
        매출 집계 요약
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleChangeOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <RowStack>
            매출 집계
            <Typography variant="subtitle1" color="#333">
              {period}
            </Typography>
          </RowStack>
        </DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <RowStack gap={4}>
            <Card>
              <Typography variant="caption" color="#999">
                매출 합계
              </Typography>
              <Typography variant="subtitle1" color="#333">
                {totalSales.toLocaleString()} 원
              </Typography>
            </Card>
            <Card>
              <Typography variant="caption" color="#999">
                마진 합계
              </Typography>
              <Typography variant="subtitle1" color="#333">
                {totalMargin.toLocaleString()} 원
              </Typography>
            </Card>
            <Card>
              <Typography variant="caption" color="#999">
                총 판매 수량
              </Typography>
              <Typography variant="subtitle1" color="#333">
                {totalQuantity.toLocaleString()} 개
              </Typography>
            </Card>
          </RowStack>
          <RowStack>
            <Card sx={{ width: "100%" }}>
              <LineChart
                margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
                width={550}
                height={400}
                series={[{ data: cumulativeSum(valueList).filter((v, i) => i % Math.ceil(valueList.length / 10) === 0) }]}
                xAxis={[{ scaleType: "point", data: dateList.filter((v, i) => i % Math.ceil(dateList.length / 10) === 0) }]}
              />
            </Card>
          </RowStack>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          <Button onClick={handleChangeOpen} variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

/**
 * 필요한것
 *
 * 1. 매출 합계
 * 2. 마진 합계
 * 3. 판매 수량
 * 4. 기간
 * 5. 매출 누적 그래프
 * 6. 정보 액셀 다운로드 버튼
 *
 */
function getDateRange(startDate: string, endDate: string) {
  const result = [];
  const currentDate = new Date(`${startDate.slice(0, 4)}-${startDate.slice(4, 6)}-${startDate.slice(6, 8)}`);
  const end = new Date(`${endDate.slice(0, 4)}-${endDate.slice(4, 6)}-${endDate.slice(6, 8)}`);

  // 현재 날짜와 종료 날짜까지 반복
  while (currentDate <= end) {
    // 날짜를 "YYYYMMDD" 형식으로 배열에 추가
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    result.push(`${year}-${month}-${day}`);

    // 하루 더하기 (한국 시간 고려)
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return result;
}

